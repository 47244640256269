import { LinkOutlined, ShoppingOutlined } from "@ant-design/icons";
import { Button, message, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchBranchMembers } from "../../../../apis/branch.api";
import {
  assignClientBM,
  clientBMCarts,
  fetchClientDetails,
} from "../../../../apis/client.api";
import { fetchUserDetails, getUserProperties } from "../../../../apis/user.api";
import { usePermissions } from "../../../../custom_hooks/permission/permission_hook";

export function MobileCartDetails() {
  const { userId } = useParams();
  const [clientDetails, setClientDetails] = useState<any>();
  const [carts, setCarts] = useState<any[]>([]);
  const [clientDetailsForPage, setClientDetailsForPage] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentOrgUser, setCurrentOrgUser] = useState<any>(null);
  const [currentUserDetails, setCurrentUserDetails] = useState<any>(null);
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientDetails(userId).then(async (res) => {
      setClientDetails(res);
    });
  }, [userId]);

  useEffect(() => {
    fetchUserDetails().then(async (res) => {
      setCurrentUserDetails(res);
      const branchUsers = await fetchBranchMembers({ search: "" });
      const orgUser = branchUsers.find(
        (branchUser: any) => branchUser?.user?.id === res?.id
      );

      setCurrentOrgUser(orgUser);
    });
  }, []);

  useEffect(() => {
    if (!clientDetails) return;

    setLoading(true);
    clientBMCarts({
      search: clientDetails?.contact,
    }).then((res) => {
      setCarts(res?.data ?? []);
      setClientDetailsForPage(res?.user_details ?? {});
      setLoading(false);
    });
  }, [clientDetails]);

  const totalPrice = carts.reduce((acc, cart) => {
    return acc + Number(cart?.quantity) * Number(cart?.variant?.md_price_gst);
  }, 0);

  const totalItems = carts.reduce((acc, cart) => {
    return acc + Number(cart?.quantity);
  }, 0);

  const getUserDetails = (userId: string) => {
    return clientDetailsForPage?.find((client) => client.id === userId);
  };

  const assignBMToClient = async (client_id: string) => {
    await assignClientBM(
      client_id,
      currentOrgUser?.id,
      currentOrgUser?.user_permission
    );
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold mb-3">
          {clientDetails?.f_name} {clientDetails?.l_name}
        </h3>
        <Link to="/carts">
          <Button type="link" size="small">
            Back
          </Button>
        </Link>
      </div>

      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: ".5rem 0",
        }}
        className="mb-4 rounded shadow p-3"
      >
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Cart Number
          </div>
          {getUserDetails(userId as string)?.user_info?.cart_number}
        </div>
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Total Price
          </div>
          Rs. {totalPrice?.toFixed(2)}
        </div>
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Total Items
          </div>
          {totalItems}
        </div>
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Total Products
          </div>
          {carts?.length}
        </div>
      </div>

      <div className="mb-3">
        <a
          href={`https://materialdepot.in/auto-login-v2?use_procurement_user_login=${clientDetails?.id}&procurement_user=${currentUserDetails?.id}&use_procurement_user_contact=${clientDetails?.contact}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
          onClick={async (ev) => {
            // assignBMToClient(clientDetails?.id);
            ev.preventDefault();
            // assignBMToClient(client?.id);
            // Get client properties to check if source and requirements are filled

            message.loading({
              content: "Assigning BM...",
              key: "cart-bm-assign",
            });
            const clientProperties = await getUserProperties(clientDetails?.id);

            const requiredProperties = hasPermission(
              "pi.create_without_properties"
            )
              ? ["Requirements", "Source", "Location"]
              : [
                  "Requirements",
                  "Source",
                  "Location",
                  "Type of Property",
                  // "Property",
                  "Project Type",
                  "Family",
                  "Age",
                ];

            const missingProperties = requiredProperties.filter(
              (property) =>
                !clientProperties?.find(
                  (userProperty: any) =>
                    userProperty?.property?.name === property
                )
            );

            if (missingProperties.length > 0) {
              message.error({
                content: `Please enter the following properties for the client: ${missingProperties.join(
                  ", "
                )}`,
                duration: 8,
                key: "cart-bm-assign",
              });

              navigate(`/client/${clientDetails?.id}/edit`);
              return;
            }

            await assignBMToClient(clientDetails?.id);

            // Also copy the link to clipboard
            const link = `https://materialdepot.in/auto-login-v2?use_procurement_user_login=${clientDetails?.id}&procurement_user=${currentUserDetails?.id}&use_procurement_user_contact=${clientDetails?.contact}`;
            navigator.clipboard.writeText(link);
            message.info("Link copied to clipboard");

            window.open(link, "_blank");
          }}
          className="mb-3 d-block"
        >
          <Button
            className="w-100"
            type="default"
            size="large"
            icon={<ShoppingOutlined />}
          >
            Open User Cart
          </Button>
        </a>
        <Button
          className="w-100"
          type="primary"
          size="large"
          onClick={() => {
            const userContact = clientDetails?.contact;
            if (!userContact) return;

            navigator.clipboard.writeText(
              `https://materialdepot.in/user/cart/?contact=${userContact}`
            );

            message.success("Cart link copied to clipboard");
          }}
          icon={<LinkOutlined />}
        >
          Copy Cart Link
        </Button>
      </div>

      <h5 className="fw-semibold mb-3">Cart Items</h5>
      {carts?.map((cart) => (
        <div key={cart?.id} className="row mb-4 align-items-center">
          <div className="col-4">
            <img
              src={cart?.variant?.variant_image?.[0]?.image_url}
              alt=""
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                aspectRatio: "1/1",
                objectFit: "contain",
              }}
              className="border"
            />
          </div>
          <div className="col-8">
            <h6
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                display: "-webkit-box",
                fontSize: 14,
              }}
              className="mb-1"
            >
              {cart?.variant?.product_name}
            </h6>
            <Space align="center" className="" style={{ fontSize: 13 }}>
              <span>
                Rs.{" "}
                {(
                  parseFloat(cart?.variant?.md_price_gst) *
                  parseFloat(cart?.quantity)
                ).toFixed(2)}
              </span>
              <span style={{ textDecoration: "line-through" }}>
                Rs.{" "}
                {(
                  parseFloat(cart?.variant?.mrp) * parseFloat(cart?.quantity)
                ).toFixed(2)}
              </span>
              <span className="text-success">
                {(
                  ((parseFloat(cart?.variant?.mrp) -
                    parseFloat(cart?.variant?.md_price_gst)) /
                    parseFloat(cart?.variant?.mrp ?? 1)) *
                  100
                ).toFixed(0)}{" "}
                % off
              </span>
            </Space>
            <Space style={{ fontSize: 13 }}>
              <span>Quantity: {cart?.quantity}</span>
            </Space>
            {cart?.lead_time !== null && (
              <div className="text-success fw-bold" style={{ fontSize: 13 }}>
                Delivery by:{" "}
                {moment()
                  .add((cart?.lead_time ?? 0) + 1, "days")
                  .format("ll")}
              </div>
            )}
            <div
              style={{ fontSize: 11 }}
              className="text-muted d-flex justify-content-between mt-1"
            >
              <div>
                {Number(cart?.quantity).toFixed(0)}{" "}
                {cart?.variant?.price_unit ?? "item"}
                {cart?.variant?.coverage_area && (
                  <>
                    &mdash;
                    {(
                      Number(cart?.variant?.coverage_area) * cart?.quantity
                    ).toFixed(0)}{" "}
                    sqft
                  </>
                )}
              </div>
              <div>
                {cart?.variant?.package_size && (
                  <span className="th-fontSize-12 text-muted">
                    1 {cart?.variant?.price_unit} -{" "}
                    {cart?.variant?.package_size}{" "}
                    {cart?.variant?.package_size_unit}
                    {cart?.variant?.coverage_area && (
                      <>
                        {" "}
                        / {Number(cart?.variant?.coverage_area).toFixed(0)} sqft
                      </>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
