import { Button, Form, Input, message, Pagination } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchBranchMembers } from "../../apis/branch.api";
import { assignClientBM, fetchClients } from "../../apis/client.api";
import { fetchUserDetails, getUserProperties } from "../../apis/user.api";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";
import { ClientAddMobile } from "../client/mobile/add";
import { ClientDetailsMobile } from "../client/mobile/details";

export function ProcurementMobileLayout() {
  const token = localStorage.getItem("token");
  const [clients, setClients] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [currentUserDetails, setCurrentUserDetails] = useState<any>(null);
  const [view, setView] = useState<"list" | "add">("list");
  const [viewDetails, setViewDetails] = useState<string>();
  const [currentOrgUser, setCurrentOrgUser] = useState<any>(null);
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) return;

    fetchClients({ params: { page, search } }).then((res) => {
      const { count, results } = res;
      setClients(results);
      setCount(count);
    });
  }, [token, page, search]);

  useEffect(() => {
    if (!token) return;

    fetchUserDetails().then(async (res) => {
      setCurrentUserDetails(res);
      const branchUsers = await fetchBranchMembers({ search: "" });
      const orgUser = branchUsers.find(
        (branchUser: any) => branchUser?.user?.id === res?.id
      );

      setCurrentOrgUser(orgUser);
    });
  }, [token]);

  const assignBMToClient = async (client_id: string) => {
    await assignClientBM(
      client_id,
      currentOrgUser?.id,
      currentOrgUser?.user_permission
    );
  };

  if (!token) {
    return (
      <div style={{ height: "100dvh" }} className="d-flex flex-column">
        <Form>
          <Form.Item name="contact">
            <Input placeholder="Contact" />
          </Form.Item>
        </Form>
      </div>
    );
  }

  const clientList = (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold mb-3">Clients</h3>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setView("add");
            }}
          >
            Add Client
          </Button>
        </div>
      </div>
      <Input.Search
        onSearch={(value) => {
          setSearch(value);
        }}
        placeholder="Search"
        className="mb-3"
      />
      <div className="text-muted mb-3">
        Showing {page === 1 ? 1 : (page - 1) * 10 + 1} -{" "}
        {page * 10 > count ? count : page * 10} of {count}
      </div>
      {clients.map((client) => (
        <div className="p-3 rounded shadow-sm mb-3 d-block">
          <div className="d-flex justify-content-between">
            {client?.f_name ? (
              <div>{client?.f_name}</div>
            ) : (
              <div className="text-muted">No Name</div>
            )}
            <div className="th-fontSize-12 text-muted">
              {moment(client?.created_at).fromNow()}
            </div>
          </div>
          <div>{client?.contact}</div>
          <div className="d-flex gap-3 mt-3">
            <a
              className="rounded-pill bg-primary text-white px-2 py-1 btn btn-sm"
              href={`https://materialdepot.in/auto-login-v2?use_procurement_user_login=${client?.id}&procurement_user=${currentUserDetails?.id}&use_procurement_user_contact=${client?.contact}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
              onClick={async () => {
                message.loading({ content: "Creating cart...", key: "cart" });

                assignBMToClient(client?.id);

                // Get client properties to check if source and requirements are filled
                const clientProperties = await getUserProperties(client?.id);

                const requiredProperties = hasPermission(
                  "pi.create_without_properties"
                )
                  ? ["Requirements", "Source", "Location"]
                  : [
                      "Requirements",
                      "Source",
                      "Location",
                      // "Type of Property",
                      // "Property",
                      // "Project Type",
                      // "Family",
                      // "Age",
                    ];

                const missingProperties = requiredProperties.filter(
                  (property) =>
                    !clientProperties?.find(
                      (userProperty: any) =>
                        userProperty?.property?.name === property
                    )
                );

                console.log("debug>>>", missingProperties);

                if (missingProperties.length > 0) {
                  message.error({
                    content: `Please enter the following properties for the client: ${missingProperties.join(
                      ", "
                    )}`,
                    duration: 8,
                    key: "cart",
                  });

                  return;
                }
                // Also copy the link to clipboard
                navigator.clipboard.writeText(
                  `https://materialdepot.in/auto-login-v2?use_procurement_user_login=${client?.id}&procurement_user=${currentUserDetails?.id}&use_procurement_user_contact=${client?.contact}`
                );
                message.info("Link copied to clipboard");
              }}
            >
              Create Cart
            </a>
            <button
              className="rounded-pill bg-primary text-white px-2 py-1 btn btn-sm"
              onClick={() => {
                setViewDetails(client?.id);
              }}
            >
              View Details
            </button>
          </div>
        </div>
      ))}
      <Pagination
        total={count}
        // showTotal={(total) => `Total ${total} items`}
        defaultPageSize={10}
        current={page}
        onChange={(page) => {
          setPage(page);
        }}
      />
    </div>
  );

  const clientAdd = (
    <div className="p-3">
      <ClientAddMobile
        onBack={() => {
          setView("list");
        }}
      />
    </div>
  );

  const clientDetails = viewDetails ? (
    <div className="p-3">
      <ClientDetailsMobile
        userId={viewDetails}
        onBack={() => {
          setViewDetails(undefined);
          setView("list");
        }}
      />
    </div>
  ) : null;

  if (viewDetails) {
    return clientDetails;
  }
  if (view === "add") {
    return clientAdd;
  }
  if (view === "list") {
    return clientList;
  }

  return null;
}
