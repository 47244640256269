import { Button, Drawer, message, Space } from "antd";
import { Header } from "antd/es/layout/layout";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  fetchBranches,
  fetchBranchGst,
  fetchBranchTerms,
} from "../../apis/branch.api";
import {
  fetchClientAddresses,
  fetchClientCart,
  fetchClientDetails,
} from "../../apis/client.api";
import { createPI } from "../../apis/pro_forma_invoice.api";
import { fetchUserDetails, getUserProperties } from "../../apis/user.api";
import ClientCartTable from "../../components/cart/cart_table";
import ClientCart from "../../components/cart/client_cart";
import AddClientAddressForm from "../../components/client/add_client_address_form";
import AddressList from "../../components/client/client_address_list";
import BasicClientDetails from "../../components/client/client_details/client_basic_details";
import ClientPITable from "../../components/client/client_pi_table";
import { UserProperty } from "../../components/client/client_properties";
import { ClientPropertiesDetails } from "../../components/client/client_properties/details";
import EditClientAddressForm from "../../components/client/edit_client_address_form";
import EditClientForm from "../../components/client/edit_client_form";
import { ClientVisitRecords } from "../../components/client/visit_records";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";
import { getExplicitUserIdPermission } from "../../custom_hooks/permission/role_container";
import { Client } from "../../interfaces/client.interface";

const ClientDetails = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const { user_id } = useParams();
  const [client_data, setClientData] = useState<Client>();
  const [cart_data, setCartData] = useState<any>(null);
  const [client_addresses, setClientAddresses] = useState(null);
  const [client_edit_drawer_open, setClientEditDrawerOpen] = useState(false);

  const [selected_address, setSelectedAddress] = useState(null);
  const [client_add_address_drawer_open, setClientAddAddressDrawerOpen] =
    useState(false);
  const [cart_drawer_open, setCartDrawerOpen] = useState(false);

  const [client_edit_address_drawer_open, setClientEditAddressDrawerOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [branch_data, setBranchData] = useState<any>({});
  const [selected_gst, setSelectedGst] = useState<any>(null);
  const [selected_term, setSelectedTerm] = useState<any>(null);
  const [clientUserProperties, setClientUserProperties] = useState<
    UserProperty[]
  >([]);
  const [currentUserDetails, setCurrentUserDetails] = useState<any>(null);

  const showClientEditAddressDrawer = (address: any) => {
    setClientEditAddressDrawerOpen(true);
    setSelectedAddress(address);
  };

  const onClientEditAddressClose = () => {
    setClientEditAddressDrawerOpen(false);
  };
  const fetchBasicDetails = () => {
    fetchClientDetails(user_id).then((res) => {
      setClientData(res);
    });
  };

  const fetchAddresses = () => {
    fetchClientAddresses(user_id).then((res) => {
      setClientAddresses(res);
    });
  };
  const fetchCart = () => {
    fetchClientCart(user_id).then((res) => {
      setCartData(res);
    });
  };

  useEffect(() => {
    fetchUserDetails().then(async (res) => {
      setCurrentUserDetails(res);
    });
    fetchBasicDetails();
    fetchAddresses();
    fetchCart();
    fetchBranches().then((res) => {
      setBranchData(res);
      fetchBranchTerms(res?.id).then((res) => {
        if (res?.length > 0) {
          setSelectedTerm(res[0]);
        }
      });
    });
    fetchBranchGst().then((res) => {
      setSelectedGst(res[0]);
    });
  }, []);

  const fetchUserProperties = () => {
    if (!user_id) return;

    getUserProperties(user_id).then((res) => {
      setClientUserProperties(res);
    });
  };

  useEffect(() => {
    fetchUserProperties();
  }, [user_id]);

  const showClientEditDrawer = () => {
    setClientEditDrawerOpen(true);
  };

  const onClientEditDrawerClose = () => {
    setClientEditDrawerOpen(false);
  };

  const showClientAddAddressDrawer = () => {
    setClientAddAddressDrawerOpen(true);
  };

  const onClientAddAddressDrawerClose = () => {
    setClientAddAddressDrawerOpen(false);
  };

  const onCartDrawerClose = () => {
    setCartDrawerOpen(false);
  };

  const downloadCartPDF = async () => {
    message.loading("Downloading PDF...");

    const pdfResponse = await axios.get(
      "https://api-dev2.materialdepot.in/apiV1/cart-pdf/",
      {
        params: {
          user_id: user_id,
        },
      }
    );
    const fileName = `${client_data?.user_info?.cart_number}_${client_data?.f_name}_cart.pdf`;

    const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const createEstimate = () => {
    setLoading(true);

    if (cart_data?.length < 1) {
      message.error("Enter at least one item!");
      setLoading(false);
      return;
    }
    console.log("1");

    let items: Array<any> = [];
    for (var i = 0; i < cart_data?.length; i++) {
      var item_data = cart_data[i]?.variant;

      var gross_margin = 0;
      var margin = 0;
      if (
        item_data?.price_list?.studio_price &&
        item_data?.price_list?.studio_price > 0 &&
        client_data?.roles == 8
      ) {
        gross_margin =
          ((parseFloat(item_data?.price_list?.studio_price ?? 0) -
            parseFloat(item_data?.price_list?.msp_price ?? 0)) /
            parseFloat(item_data?.price_list?.studio_price ?? 1)) *
          100;
        margin = gross_margin / (1 - gross_margin / 100);
      }

      if (
        item_data?.price_list?.public_price &&
        item_data?.price_list?.public_price > 0 &&
        client_data?.roles == 10
      ) {
        gross_margin =
          ((parseFloat(item_data?.price_list?.public_price ?? 0) -
            parseFloat(item_data?.price_list?.msp_price ?? 0)) /
            parseFloat(item_data?.price_list?.public_price ?? 1)) *
          100;
        margin = gross_margin / (1 - gross_margin / 100);
      }
      const formData = {
        boq_item: item_data.product_name,
        quantity: Number(cart_data[i].quantity).toFixed(2),
        unit: item_data.price_unit,
        hsn_code: item_data.sku,
        brand: item_data?.product?.brand?.brand_name,
        unit_selling_price: (
          Number(item_data?.price_list?.msp_price) /
          (item_data?.gst ? parseFloat(item_data?.gst) / 100 + 1 : 1.18)
        ).toFixed(2),
        margin: Number(margin)?.toFixed(2),
        tax_value: Number(item_data.gst)?.toFixed(2),
        tax_type: "cgst_sgst",
        mrp: Number(item_data.mrp).toFixed(2),
        variant: item_data?.id,
        item_image: item_data?.variant_image[0]?.image_url ?? null,
        product_handle: item_data?.product?.product_handle,
        variant_handle: item_data.variant_handle,
        category_name: item_data?.product?.category?.category_name,
        landing_price: (
          Number(item_data.md_price_gst) /
          (item_data.gst ? Number(item_data.gst) / 100 + 1 : 1.18)
        ).toFixed(2),
        item_description: item_data?.description,
        lead_time: 0,
        remarks: "",
        vendor: item_data?.vendor?.map((vendor: any) => vendor?.vendor__id),
      };
      items.push(formData);
    }
    console.log("2 items", items);

    let amount = 0;
    items?.map((item: any) => {
      amount +=
        Number(item?.quantity) *
        ((Number(item?.unit_selling_price) +
          Number(item?.unit_selling_price) * (Number(item?.margin) / 100)) *
          ((Number(item?.tax_value) + 100) / 100));
    });
    console.log("3 amount", amount);

    let payload = {
      client: client_data?.id,
      terms_and_condition: selected_term?.id,
      total_estimate_amount: Number(amount).toFixed(2),
      terms_and_conditions_description: selected_term?.description,
      gst: selected_gst?.id,
      estimate_status: "draft",
      bill_address: null,
      bill_address_description: null,
      ship_address: null,
      ship_address_description: null,
      estimate_additionals: [],
      estimate_payment_terms: [
        {
          term: "Payment in Advance",
          percentage: 100,
          deadline: moment().format("YYYY-MM-DD"),
        },
      ],
      branch: branch_data?.id,
      estimate_items: items,
      image: true,
      documents: [],
      assigned_to: null,
    };
    console.log("4 payload", payload);

    createPI({ ...payload }).then((res) => {
      setLoading(false);
      if (res) {
        navigate("/pro-forma-invoice-details/" + res?.id);
      } else {
      }
    });
  };
  return (
    <>
      <Drawer
        title="Edit Address"
        placement="right"
        closable={false}
        onClose={onClientEditAddressClose}
        open={client_edit_address_drawer_open}
      >
        <EditClientAddressForm
          address={selected_address}
          fetchAddresses={fetchAddresses}
          onClientEditAddressClose={onClientEditAddressClose}
        />
      </Drawer>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="primary-color-text th-font-size-20 th-font-weight-700">
            Client Details
          </div>
          <Space>
            {(hasPermission("pi.create") ||
              getExplicitUserIdPermission("pi.create").includes(
                currentUserDetails?.id
              )) && (
              <Link to={"/create-pro-forma-invoice/" + client_data?.id}>
                <Button size="middle" className="fw-bold" type="primary">
                  Create PI
                </Button>
              </Link>
            )}
            <Button
              type="default"
              onClick={() => downloadCartPDF()}
              disabled={cart_data?.length < 1}
            >
              Download PDF
            </Button>
          </Space>
        </div>
      </Header>

      <div>
        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="row gap-2 my-4">
            <div className="col">
              <BasicClientDetails
                showClientEditDrawer={showClientEditDrawer}
                user_id={user_id}
                client_data={client_data ?? null}
              />
            </div>
          </div>
          <div className="my-4">
            <ClientPropertiesDetails
              properties={clientUserProperties}
              clientId={user_id}
            />
          </div>
        </div>

        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="row gap-2 my-4">
            <div className="col">
              <h4>
                Visit Records ({client_data?.user_footfall_record?.length ?? 0})
              </h4>
              <ClientVisitRecords clientDetails={client_data} />
            </div>
          </div>
        </div>

        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="row gap-2 my-4">
            <div className="col">
              <AddressList
                address_list={client_addresses ?? []}
                fetchAddresses={fetchAddresses}
                showClientAddAddressDrawer={showClientAddAddressDrawer}
                showClientEditAddressDrawer={showClientEditAddressDrawer}
              />
            </div>
          </div>
        </div>

        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="pb-3 d-flex justify-content-between">
            <div className="align-middle th-font-size-18 th-font-weight-600  primary-color-text">
              Client PIs
            </div>
          </div>
          <ClientPITable client_data={client_data} />
        </div>

        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="row gap-2 my-4">
            <div className="col">
              <div className="pb-3 d-flex justify-content-between">
                <div className="align-middle th-font-size-18 th-font-weight-600  primary-color-text">
                  Cart Details ({cart_data?.length ?? 0} items)
                </div>
                <div className="d-flex gap-2">
                  {(hasPermission("pi.create") ||
                    getExplicitUserIdPermission("pi.create").includes(
                      currentUserDetails?.id
                    )) && (
                    <Button
                      onClick={() => {
                        createEstimate();
                      }}
                      loading={loading}
                      size="middle"
                      className="fw-bold"
                      type="primary"
                    >
                      Create PI from cart
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      setCartDrawerOpen(true);
                    }}
                    loading={loading}
                    size="middle"
                    className="fw-bold"
                    type="primary"
                  >
                    See details
                  </Button>
                </div>
              </div>
              {/* <ClientCart
                cart_items={cart_data ?? []}
                createEstimate={createEstimate}
                loading={loading}
                setLoading={setLoading}
              /> */}
              <ClientCartTable
                cart_items={cart_data ?? []}
                createEstimate={createEstimate}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Edit Client"
        placement="right"
        closable={false}
        onClose={onClientEditDrawerClose}
        open={client_edit_drawer_open}
      >
        <EditClientForm
          client={client_data ?? {}}
          onClientEditDrawerClose={onClientEditDrawerClose}
          fetchBasicDetails={fetchBasicDetails}
          fetchExtraDetails={fetchUserProperties}
        />
      </Drawer>

      <Drawer
        title="Add Address"
        placement="right"
        onClose={onClientAddAddressDrawerClose}
        open={client_add_address_drawer_open}
        drawerStyle={{ height: "100%" }}
      >
        <AddClientAddressForm
          user_id={user_id}
          onClientAddAddressDrawerClose={onClientAddAddressDrawerClose}
          fetchAddresses={fetchAddresses}
        />
      </Drawer>

      <Drawer
        title=""
        placement="right"
        width={1000}
        closable={false}
        onClose={onCartDrawerClose}
        open={cart_drawer_open}
      >
        <ClientCart
          cart_items={cart_data ?? []}
          createEstimate={createEstimate}
          loading={loading}
          setLoading={setLoading}
        />
      </Drawer>
    </>
  );
};

export default ClientDetails;
