import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  MenuProps,
  Popconfirm,
  Table,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { fetchBranches } from "../../../apis/branch.api";
import { fetchClientDetails } from "../../../apis/client.api";
import {
  cancelPurchaseOrder,
  editPurchaseOrder,
  getPOInvoices,
  getPOLog,
  getPurchaseOrderDetails,
  removeInvoiceFromPo,
  resendPOEmail,
} from "../../../apis/po.api";
import {
  PowoDeliveryChallanOpenApi,
  PowoPdfOpenApi,
} from "../../../apis/routes";
import { DispatchTimeChangeReasonDrawer } from "../../../components/po/dispatch_time_change_drawer";
import POBillingDetails from "../../../components/po/po_details/po_billing_details";
import POOrderDetails from "../../../components/po/po_details/po_order_details";
import TaxAmount from "../../../components/po/tax_amount";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { getPODeliveryStatusLabel } from "../../../helpers/misc_helper";
import { Client } from "../../../interfaces/client.interface";

const PurchaseOrderDetails = () => {
  const { po_id } = useParams();
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [branch_data, setBranchData] = useState<any>({});

  const [poLog, setPoLog] = useState<any>([]);
  const [showPoLog, setShowPoLog] = useState<boolean>(false);

  const [client_data, setClientData] = useState<Client | null>(null);
  const [invoices, setInvoices] = useState<Array<any>>([]);

  const [toChangeDipatchTime, setToChangeDispatchTime] = useState<
    string | null
  >(null);
  const [
    showDispatchTimeChangeReasonDrawer,
    setShowDispatchTimeChangeReasonDrawer,
  ] = useState<boolean>(false);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className={
            hasPermission("po.update") ? "" : "unclickable grey-color-text"
          }
          onClick={() => {
            window.location.href = "/edit-po/" + po_id;
          }}
        >
          Edit PO
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() => {
            setShowPoLog(true);
          }}
        >
          View PO Log
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            window.open(PowoPdfOpenApi + po_id + "?image=true", "_blank");
          }}
        >
          Download pdf
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <Popconfirm
          className={
            hasPermission("po.update") ? "" : "unclickable grey-color-text"
          }
          title="Are you sure to cancel PO?"
          onConfirm={() => onDelete()}
        >
          <div key="3">Cancel Po</div>
        </Popconfirm>
      ),
    },

    {
      key: "4",
      label: (
        <div
          onClick={() => {
            window.open(PowoDeliveryChallanOpenApi + po_id, "_blank");
          }}
        >
          Download Delivery Challan
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          className={
            hasPermission("po.update") ? "" : "unclickable grey-color-text"
          }
          onClick={() => {
            resendPOEmail(po_id ?? "");
          }}
        >
          Resend PO Mail
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "BOQ Item",
      key: "private_label_product_name",
      width: 200,
      render: (data: { private_label_product_name: any; boq_item: any }) => {
        return (
          <>
            <div>
              {" "}
              {hasPermission("po.public_name")
                ? data?.boq_item
                : data?.private_label_product_name ?? data?.boq_item}
            </div>
          </>
        );
      },
    },
    ...(hasPermission("po.public_name")
      ? [
          {
            key: "private_label_name",
            title: "Private Label Name",
            width: 200,
            render: (data: { private_label_product_name: any }) => {
              return (
                <>
                  <div>{data?.private_label_product_name}</div>
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "Category",
      key: "category_name",
      width: 100,
      render: (data: { category_name: any }) => {
        return (
          <>
            <div>{data?.category_name}</div>
          </>
        );
      },
    },
    {
      title: "Quantity",
      key: "quantity",
      width: 50,
      render: (data: { quantity: any }) => {
        return (
          <>
            <div>{data?.quantity}</div>
          </>
        );
      },
    },
    {
      title: "Unit",
      key: "unit",
      width: 60,
      render: (data: { unit: any }) => {
        return (
          <>
            <div>{data?.unit}</div>
          </>
        );
      },
    },
    {
      title: "Brand",
      key: "brand",
      width: 130,
      render: (data: { private_label_brand_name: any; brand: any }) => {
        return (
          <>
            <div> {data?.private_label_brand_name ?? data?.brand}</div>
          </>
        );
      },
    },
    {
      title: "Price",
      key: "unit_po_price",
      width: 100,
      render: (data: any) => {
        return (
          <>
            <div>₹{data?.unit_po_price}</div>
          </>
        );
      },
    },
    {
      title: "Tax",
      key: "tax",
      render: (data: { tax_value: any }) => {
        var tax = Number(data?.tax_value);
        return (
          <>
            <div>{tax}%</div>
          </>
        );
      },
    },
    {
      title: "Tax Type",
      key: "tax_type",
      render: (data: { tax_type: string }) => (
        <>
          {data?.tax_type === "igst" && "IGST"}
          {data?.tax_type === "cgst_sgst" && "CGST & SGST"}
          {data?.tax_type === "none" && "None"}
        </>
      ),
    },
    {
      title: "Amount",
      key: "total_price",
      dataIndex: "total_price",
    },
  ];

  const fetchInvoicesOfPO = () => {
    setLoading(true);
    getPOInvoices(po_id).then((res) => {
      setInvoices(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchInvoicesOfPO();
    getPurchaseOrderDetails(po_id ?? "").then((res) => {
      let dataRes = res;
      console.log("dataRes", dataRes);
      dataRes.po_items = dataRes.po_items?.map((item: any) => {
        return {
          ...item,
          ...item?.estimate_item,
          total_price: item?.total_price,
          tax_value: item?.tax_value,
          quantity: item?.quantity,
          tax_type: item?.tax_type,
        };
      });
      console.log("dataRes2", dataRes);

      getPOLog({ po_number: dataRes?.po_number }).then((res) => {
        try {
          setPoLog(
            res?.map((item: any) => ({
              ...item,
              change: JSON.parse(item.change),
            }))
          );
        } catch (e) {
          setPoLog(
            res?.map((item: any) => ({
              ...item,
              change: { change: item?.change },
            }))
          );
        }
      });

      setData({ ...dataRes });
    });
    fetchBranches().then((res) => {
      setBranchData(res);
    });
  }, []);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };

  useEffect(() => {
    if (data?.estimate?.client) {
      fetchBasicDetails(data?.estimate?.client?.id ?? data?.estimate?.client);
    }
  }, [data]);
  const onDelete = () => {
    const formData = new FormData();
    formData.append("is_deleted", "true");
    cancelPurchaseOrder(po_id, formData).then((res) => {
      navigate("/po-list");
    });
  };

  const confirm = async (invoice_id: any) => {
    if (!loading) {
      setLoading(true);
      await removeInvoiceFromPo(invoice_id).then((res) => {
        fetchInvoicesOfPO();
      });
      setLoading(false);
    }
  };

  const cancel = () => {};

  const handleChangeDispatchDateTime = (dateString: string) => {
    setToChangeDispatchTime(dateString);
    setShowDispatchTimeChangeReasonDrawer(true);
  };

  const onDispatchDateChangeReasonSet = (values: any) => {
    setShowDispatchTimeChangeReasonDrawer(false);
    let editData = {
      ...values,
      dispatch_time: toChangeDipatchTime,
    };

    editPurchaseOrder(po_id, editData).then((res) => {
      message.success("Dispatch time updated successfully");
      getPurchaseOrderDetails(po_id ?? "").then((res) => {
        let dataRes = res;
        console.log("dataRes", dataRes);
        dataRes.po_items = dataRes.po_items?.map((item: any) => {
          return {
            ...item,
            ...item?.estimate_item,
            total_price: item?.total_price,
            tax_value: item?.tax_value,
            quantity: item?.quantity,
            tax_type: item?.tax_type,
          };
        });
        console.log("dataRes2", dataRes);

        setData({ ...dataRes });
      });
      fetchBranches().then((res) => {
        setBranchData(res);
      });
    });
  };

  return (
    <section className={`lighter-grey-color-bg`}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4 align-items-center">
            <div className="primary-color-text th-font-size-20 th-font-weight-700">
              PO Details
            </div>
            <div className=" th-font-weight-600">
              PO No. :
              <span className=" th-font-weight-400"> {data?.po_number}</span>
            </div>
            <div className=" th-font-weight-600">
              Lead ID :
              <span className=" th-font-weight-400">
                {" "}
                {data?.estimate?.lead_id}
              </span>
            </div>
            {/* <div className=" th-font-weight-600">
              Quotation No. :
              <span className=" th-font-weight-400">
                {" "}
                {data?.estimate?.estimate_no}
              </span>
            </div> */}
          </div>
          <div className="d-flex gap-4 align-items-center">
            <div className=" th-font-weight-600">
              PO Delivery Status:
              <span className=" th-font-weight-400">
                {" "}
                {data?.is_deleted
                  ? "PO cancelled"
                  : getPODeliveryStatusLabel(data?.po_delivery_status)}
              </span>
            </div>
            {data?.is_deleted ? (
              <></>
            ) : (
              <Dropdown menu={{ items }} placement="bottomRight">
                <Button
                  type="primary"
                  className="th-font-weight-700 d-flex align-items-center"
                >
                  Actions
                  <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
      </Header>
      <div>
        <div className="m-4 p-4 px-5 white-color-bg">
          <img
            alt="logo"
            src="/main_logo.svg"
            style={{ height: "80px" }}
            className="mt-2"
          />
          <div className="row gap-2 my-4">
            <div className="col">
              <POBillingDetails
                name="MDEPOT RETAIL TECHNOLOGIES PRIVATE LIMITED"
                address="Site No. 15, Bannerghatta Main Road, Sarakki Industrial layout, Jp Nagar phase 3, Bengaluru 560076"
                gst_no="29AARCM7928H1ZO"
              />
            </div>
            <div className="col">
              <POOrderDetails
                live_location_link={data?.live_location_link ?? ""}
                created_by={data?.created_by}
                created_at={data?.created_at}
                estimate_created_at={data?.estimate?.created_at}
                client={client_data?.f_name ?? ""}
                lead_id={data?.estimate?.lead_id}
                quotation_no={data?.estimate?.estimate_no}
                status={data?.estimate?.estimate_status}
                bank_details={{
                  bank_account_number: branch_data?.bank_account_number,
                  bank_ifsc: branch_data?.bank_ifsc,
                  branch_upi: branch_data?.branch_upi,
                }}
                dispatch_time={data?.dispatch_time}
                handleChangeDispatchDateTime={handleChangeDispatchDateTime}
                estimate_additional_charges={
                  data?.estimate?.estimate_additional_charges ?? []
                }
              />
            </div>
          </div>

          <Divider />
          <div className="row gap-2 my-4">
            {data?.address_description && (
              <div className="col">
                <div className=" p-3 py-2 row mb-2">
                  <div className=" th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                    Ship To
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Address Label{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.address?.name}</div>
                      </div>
                    </div>
                  </div>

                  {data?.address?.flat_no && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">Flat No.</div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>{data?.address?.flat_no}</div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.address?.address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Pincode </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.address?.pincode}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact person{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.address?.contact_person_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact number
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.address?.contact_person_contact}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Selected address
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.address_description}</div>
                      </div>
                    </div>
                  </div>
                  {data?.address?.google_map_address && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">
                        Google Map Address
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>
                            <a
                              href={data?.address?.google_map_address}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {data?.address?.google_map_address?.length >
                              50 ? (
                                <span>
                                  {data?.address?.google_map_address?.slice(
                                    0,
                                    50
                                  )}
                                  ...
                                </span>
                              ) : (
                                data?.address?.google_map_address
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!data?.address?.google_map_address &&
                    data?.address?.lat &&
                    data?.address?.long && (
                      <div className="row">
                        <div className="col-4 th-font-weight-500">
                          Google Map Address
                        </div>
                        <div className="col-7">
                          <div className="d-flex">
                            <div className="pe-1">:</div>
                            <div>
                              <a
                                href={`https://google.com/maps?q=${data?.address?.lat},${data?.address?.long}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://google.com/maps?q={data?.address?.lat},
                                {data?.address?.long}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
            {data?.vendor && (
              <div className="col">
                <div className=" p-3 py-2 row mb-2">
                  <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                    Vendor Details
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Vendor Name </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.vendor?.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.vendor?.billing_address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Vendor Code</div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.vendor?.vendor_code}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact Details
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>
                          {data?.vendor?.contact}, {data?.vendor?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">GST</div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.vendor?.gst}</div>
                      </div>
                    </div>
                  </div>

                  {data?.vendor_warehouse && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">Warehouse</div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>
                            {data?.vendor_warehouse?.name}
                            <div>{data?.vendor_warehouse?.address}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={data?.po_items}
              scroll={{ x: "max-content" }}
              rowKey={(record) => record.id}
            />
          </div>
          <div className="row">
            <div className="col">
              <div>
                <h6 className="th-font-weight-600">Payment Terms</h6>
                <div>
                  {data?.po_payment_terms?.map((each: any, ind: number) => (
                    <div key={ind}>
                      {each?.term}:{each?.percentage}%
                    </div>
                  ))}
                </div>
              </div>
              <Divider />
              <div>
                <div>
                  <h6 className="th-font-weight-600">Terms and Conditions</h6>
                  <div>
                    <div className="mb-2">
                      {data?.terms_and_conditions_description}
                    </div>
                  </div>
                </div>
              </div>
              <Divider />

              {/* <div>
                <div className="">
                  <div>
                    <h6 className="th-font-weight-600">Documents</h6>{" "}
                    {"(Total files size should be less than 25MB)"}
                  </div>
                  {data?.documents?.map((document: any, index: any) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <div
                          style={{
                            border: "1px #D1D0CE solid",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            // openInNewTab(document?.file);
                          }}
                        >
                          {document?.file_name ??
                            document?.file?.replace(
                              "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/media/porject_files/",
                              ""
                            )}
                        </div>
                      </div>
                    );
                  })}
                  {data?.old_attachment !=== "" &&
                    JSON.parse(data?.old_attachment ?? "[]")?.map(
                      (document) => {
                        return (
                          <div style={{ padding: 8 }}>
                            <div
                              style={{
                                border: "1px #D1D0CE solid",
                                padding: 4,
                                cursor: "pointer",
                                fontWeight: 500,
                              }}
                              onClick={() => {
                                const extension = document.split(".").pop();
                                handlePdfDownload(
                                  document.replace(`.${extension}`, ""),
                                  extension
                                );
                                // openInNewTab(
                                //   "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/" +
                                //     document
                                // );
                              }}
                            >
                              {document ?? ""}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div> */}
            </div>
            <div className="col">
              <div>
                <div className="d-flex justify-content-between">
                  {/* <div className="">Taxable Amount</div> */}
                  <div className="">
                    {/* ₹ {Number(getTotalTaxableAmount()).toFixed(2)} */}
                  </div>
                </div>
                <TaxAmount items={data?.po_items ?? []} />
              </div>
              <Divider />
              <div>
                {data?.po_additional_charges?.map((each: any, ind: number) => (
                  <>
                    <div className="d-flex justify-content-between" key={ind}>
                      <div className="">
                        {each?.charge_type}
                        {` (@${each?.tax_value + "%"} ${each?.tax_type})`}
                      </div>
                      <div className="">
                        ₹{" "}
                        {Number(
                          Number(each?.amount) +
                            Number(each?.amount) *
                              Number(each?.tax_value) *
                              0.01
                        ).toFixed(2)}
                      </div>
                    </div>
                    {each.remark && each.remark !== "" && (
                      <div>Remark: {each.remark}</div>
                    )}
                  </>
                ))}
              </div>
              <Divider />
              <div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6>Total Amount(Rounded off)</h6>
                  </div>
                  <div className="">
                    ₹ {Number(data?.total_po_raised_amount)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div>
            <div className="th-font-size-18 th-font-weight-500">Invoices</div>
            <div className="d-flex gap-2 py-4 flex-wrap">
              {invoices?.map((invoice) => {
                return (
                  <div className="p-3 px-4 border rounded-3 cursor-pointer">
                    <div className="th-font-size-16">
                      Invoice number : {invoice?.invoice_number}
                    </div>
                    <div className="th-font-size-16">
                      Invoice amount : {invoice?.invoice_value}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this invoice?"
                        onConfirm={() => {
                          confirm(invoice?.id);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="d-flex align-items-center"
                          danger
                          loading={loading}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button
                        className=""
                        loading={loading}
                        onClick={() => {
                          window.open(
                            String(
                              invoice?.invoice_file ??
                                invoice?.invoice_file_link
                            ).replaceAll(
                              "mysite-powo-pdfs",
                              "materialdepotimages"
                            ),
                            "_blank"
                          );
                        }}
                      >
                        Open
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        open={showDispatchTimeChangeReasonDrawer}
        onClose={() => {}}
        width={400}
        title="Change Dispatch Time Reason"
      >
        <DispatchTimeChangeReasonDrawer
          onFinish={onDispatchDateChangeReasonSet}
        />
      </Drawer>
      <Drawer
        open={showPoLog}
        onClose={() => setShowPoLog(false)}
        width={400}
        title="PO Log"
      >
        <div>
          {poLog.map((log: any, index: number) => {
            return (
              <div
                key={index}
                style={{
                  borderBottom: "1px solid #e8e8e8",
                }}
                className="p-2"
              >
                <div>
                  {Object.entries(log?.change).map(([key, value]) => {
                    return (
                      <div className="my-1">
                        <span style={{ fontWeight: 500 }}>{key}</span> :{" "}
                        <span title={JSON.stringify(value)}>
                          {JSON.stringify(value).length > 50
                            ? JSON.stringify(value).substring(0, 50) + "..."
                            : JSON.stringify(value)}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div>
                  by <small>{log?.modified_by__f_name}</small> at{" "}
                  <small>{new Date(log?.created_at)?.toLocaleString()}</small>
                </div>
              </div>
            );
          })}
        </div>
      </Drawer>
    </section>
  );
};

export default PurchaseOrderDetails;
