import {
  CheckOutlined,
  FilterOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Input, Select, Space, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { fetchBranchMembers } from "../../../apis/branch.api";
import { clientBMCartsV2 } from "../../../apis/client.api";

export function DesktopBMCarts() {
  const [orgUsers, setOrgUsers] = useState<any[]>([]);
  const [carts, setCarts] = useState<any[]>([]);
  const [selectedBM, setSelectedBM] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useState<
    "-lead_time_updated_at" | "-cart_value"
  >("-lead_time_updated_at");
  const [selectedFilter, setSelectedFilter] = useState<
    "all" | "pending" | "done"
  >("all");
  const [totalClients, setTotalClients] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cartValueGTE, setCartValueGTE] = useState<number>(0);

  useEffect(() => {
    fetchBranchMembers().then((users) => {
      setOrgUsers(users);
    });
  }, []);

  useEffect(() => {
    const bmCartParams = {
      page,
      search,
      ordering,
      user_organisation: selectedBM,
      skip_bm_filter: selectedBM === null || selectedBM === undefined,
      cart_value__gte: cartValueGTE,
    } as any;
    if (selectedFilter !== "all") {
      bmCartParams.all_lead_time_filled = selectedFilter === "done";
    }
    setLoading(true);
    clientBMCartsV2(bmCartParams)
      .then((res) => {
        setCarts(res?.results ?? []);
        setTotalClients(res?.count ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, search, ordering, selectedFilter, selectedBM, cartValueGTE]);

  const columns = useMemo(
    () => [
      {
        title: "Client",
        dataIndex: "user_name",
        key: "client",
      },
      {
        title: "Contact",
        dataIndex: "user_contact",
        key: "contact",
      },
      {
        title: "Cart Number",
        dataIndex: "cart_number",
        key: "cart_number",
      },
      {
        title: "Cart Value",
        dataIndex: "cart_value",
        key: "cart_value",
        render: (value: any) => `₹${Number(value ?? -1)?.toFixed(2)}` as string,
      },
      {
        title: "Total Products",
        dataIndex: "total_products",
        key: "total_products",
      },
      {
        title: "Total Items",
        dataIndex: "total_items",
        key: "total_items",
      },
      {
        title: "Delivery Timeline",
        key: "delivery_timeline",
        render: (data: any) => {
          return data?.all_lead_time_filled
            ? "Check Complete"
            : "Check Pending";
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (data: any) => {
          return (
            <Space>
              <Link to={`/client/${data?.user_id}`}>
                <Button type="default" size="small">
                  View Details
                </Button>
              </Link>
            </Space>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <Space>
          <Select
            placeholder="Amount"
            allowClear
            value={cartValueGTE}
            style={{ width: 170 }}
            onChange={(e) => {
              setCartValueGTE(e);
            }}
            options={[
              { value: 0, label: "All Cart Value" },
              { value: 1000, label: "1K+" },
              { value: 5000, label: "5K+" },
              { value: 10000, label: "10K+" },
              { value: 50000, label: "50K+" },
              { value: 100000, label: "1L+" },
              { value: 500000, label: "5L+" },
              { value: 1000000, label: "10L+" },
            ]}
          />
          <Select
            value={selectedBM}
            onChange={(value) => {
              setPage(1);
              setSelectedBM(value);
            }}
            placeholder="Select BM"
            className="rounded-pill"
            style={{ width: 200 }}
            showSearch
            allowClear
          >
            {orgUsers.map((orgUser) => (
              <Select.Option key={orgUser.id} value={orgUser.id}>
                {orgUser?.user?.f_name}
              </Select.Option>
            ))}
          </Select>
          <Input.Search
            onSearch={(value) => {
              setPage(1);
              setSearch(value);
            }}
            allowClear
            placeholder="Search with Client Name or Phone No..."
            className="rounded-pill"
            style={{ flexGrow: 1 }}
          />
          <Dropdown
            menu={{
              items: [
                {
                  key: "all",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {selectedFilter === "all" && <CheckOutlined />}
                      <span>All</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setSelectedFilter("all");
                  },
                },
                {
                  key: "pending",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {selectedFilter === "pending" && <CheckOutlined />}
                      <span>Pending</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setSelectedFilter("pending");
                  },
                },
                {
                  key: "done",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {selectedFilter === "done" && <CheckOutlined />}
                      <span>Completed</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setSelectedFilter("done");
                  },
                },
              ],
            }}
          >
            <Button
              icon={<FilterOutlined className="" />}
              // className="d-none"
            >
              Filter
            </Button>
          </Dropdown>
          <Dropdown
            menu={{
              items: [
                {
                  key: "-lead_time_updated_at",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {ordering === "-lead_time_updated_at" && (
                        <CheckOutlined />
                      )}
                      <span>Latest Updated</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setOrdering("-lead_time_updated_at");
                  },
                },
                {
                  key: "-cart_value",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {ordering === "-cart_value" && <CheckOutlined />}
                      <span>Cart Value</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setOrdering("-cart_value");
                  },
                },
              ],
            }}
          >
            <Button icon={<SortAscendingOutlined />}>Sort</Button>
          </Dropdown>
        </Space>
      </div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={carts}
        pagination={{
          current: page,
          total: totalClients,
          pageSize: 10,
          onChange: (page) => setPage(page),
        }}
        scroll={{ x: 800 }}
      />
    </div>
  );
}
