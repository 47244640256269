import { DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Pagination,
  Select,
  Space,
  Table,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  editPI,
  fetchProFormaInvoices,
} from "../../../apis/pro_forma_invoice.api";
import DeliveryAddressDrawer from "../../../components/pro_forma_invoice/delivery_address_drawer";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { getFormattedDate } from "../../../helpers/date_helper";
import OrderLostReasonDrawer from "../../pro_forma_invoice/order_lost_drawer";
import { OrderPlacedDetailsDrawer } from "../../pro_forma_invoice/order_placed_details_drawer";

export interface ClientPITableProps {
  client_data: any;
}
const { Option } = Select;

interface DataType {
  id: number;
  created_by: string;
  assigned_to: null | string;
  organisation: number;
  vendor: string | null;
  terms_and_condition: number;
  total_estimate_amount: number;
  created_at: string;
  modified_at: string;
  estimate_payment_terms: Array<number>;
  documents: Array<any>;
  terms_and_conditions_description: string;
  is_deleted: false;
  deleted_by: string | null;
  gst: number;
  project: any;
  ship_address: string | null;
  ship_address_description: string | null;
  estimate_items: Array<number>;
  estimate_additional_charges: Array<number>;
  lead_id: string;
  payment_description: string | null;
  gst_description: string | null;
  bill_address: string | null;
  bill_address_description: string | null;
  is_draft: boolean;
  is_accepted: boolean;
  is_revised: boolean;
  status: string;
  estimate_no: string | null;
  estimate_status: string | null;
  lost_reason: string | string | null;
  image: boolean;
}
const order_stages = ["order_confirmed", "under_dispatched", "delivered"];

const ClientPITable = ({ client_data }: ClientPITableProps) => {
  const { hasPermission } = usePermissions();

  const order_statuses = [
    "order_placed",
    "order_confirmed",
    "partly_shipped",
    "shipped",
    "partly_delivered",
    "delivered",
  ];
  const [show_order_placed_details_drawer, setShowOrderPlacedDetailsDrawer] =
    useState(false);
  const statusOptions = [
    { id: "1", value: "draft", label: "Draft", disabled: true },
    {
      id: "13",
      value: "incomplete",
      label: "Incomplete",
      disabled: !hasPermission("refund.approve"),
    },
    {
      id: "2",
      value: "availability_check",
      label: "Request Delivery Timeline",
    },
    {
      id: "3",
      value: "quote_approval_pending",
      label: "Quote Approval pending",
    },
    {
      id: "4",
      value: "order_placed",
      label: "Order Placed",
      disabled: hasPermission("refund.approve") ? false : true,
    },
    {
      id: "5",
      value: "order_lost",
      label: "Order lost",
    },
    {
      id: "12",
      value: "order_cancelled",
      label: "Order Cancelled",
    },
    {
      id: "6",
      value: "order_confirmed",
      label: "Order confirmed",
      disabled: true,
    },
    {
      id: "7",
      value: "partly_shipped",
      label: "Partly shipped",
      disabled: true,
    },
    {
      id: "8",
      value: "shipped",
      label: "Shipped",
      disabled: true,
    },
    {
      id: "9",
      value: "partly_delivered",
      label: "Partly delivered",
      disabled: true,
    },
    {
      id: "10",
      value: "delivered",
      label: "Delivered",
      disabled: true,
    },
    {
      id: "11",
      value: "refunded",
      label: "Refunded",
    },
  ];

  const onOrderPlacedDetailsFinish = (values: any) => {
    setShowOrderPlacedDetailsDrawer(false);
    editPI(pi_data?.id, {
      ...values,
      order_placed_time: moment(new Date()).add(5.5, "hours").toISOString(),
      estimate_status: "order_placed",
    }).then((res) => {
      // message.success("PI updated successfully");
      message.info(
        "Whatsapp message sent to client. PI will be marked as order placed once client confirms."
      );
      getClientPIs();
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Lead ID",
      key: "lead_id",
      dataIndex: "lead_id",
      width: 160,
    },
    // {
    //   title: "Pro Forma Invoice No.",
    //   key: "estimate_no",
    //   dataIndex: "estimate_no",
    // },
    {
      title: "Client",
      key: "client_data",
      render: (data) => <>{data?.client_data?.f_name}</>,
    },
    {
      title: "Creation date",
      key: "creation_date",
      render: (data) => <>{getFormattedDate(data?.created_at)}</>,
    },
    {
      title: "Created by",
      key: "created_by",
      dataIndex: "created_by",
    },
    {
      title: "Assigned to",
      key: "assigned_to",
      render: (data) => <>{data?.assigned_to?.f_name}</>,
    },
    {
      title: "Status",
      key: "estimate_status",
      render: (data) => (
        <Space>
          <Select
            style={{ width: 210 }}
            onChange={(e) => {
              console.log("e", e);
              if (
                (data?.ship_address != null && e == "order_placed") ||
                (e != "order_placed" && e != "order_lost")
              ) {
                if (e === "order_placed") {
                  setPIData({
                    ...data,
                    bill_address: data?.bill_address?.id,
                    ship_address: data?.ship_address?.id,
                    estimate_status: e,
                  });
                  setShowOrderPlacedDetailsDrawer(true);
                  return;
                }
                editPI(data?.id, {
                  estimate_status: e,
                }).then((res) => {
                  getClientPIs();
                });
              } else if (e == "order_lost") {
                setPIData({
                  ...data,
                  bill_address: data?.bill_address?.id,
                  ship_address: data?.ship_address?.id,
                });
                setOrderLostDrawerVisible(true);
              } else {
                setPIData({
                  ...data,
                  bill_address: data?.bill_address?.id,
                  ship_address: data?.ship_address?.id,
                });
                setDeliveryAddressDrawerVisible(true);
              }
            }}
            disabled={
              hasPermission("order.update")
                ? false
                : order_statuses.includes(data?.estimate_status)
            }
            placeholder="Select status"
            value={data?.estimate_status}
          >
            {statusOptions?.map((obj: any) => (
              <Option key={obj?.id} value={obj?.value}>
                {obj?.label}
              </Option>
            ))}
          </Select>
        </Space>
      ),
    },
    {
      title: "No. of POs raised",
      key: "po_count",
      render: (data) => (
        <div
          style={{
            width: 80,
          }}
        >
          {data?.po_count ?? ""}
        </div>
      ),
    },
    {
      title: "Total item quantity",
      key: "estimate_item_count",
      render: (data) => (
        <div
          style={{
            width: 80,
          }}
        >
          {data?.item_count?.estimate_item_count ?? ""}
        </div>
      ),
    },
    {
      title: "Po raised item quantity",
      key: "po_count",
      render: (data) => (
        <div
          style={{
            width: 150,
          }}
        >
          {data?.item_count?.po_item_count ?? ""}
        </div>
      ),
    },
    {
      title: "Total",
      key: "total_estimate_amount",
      render: (data) => (
        <>{parseFloat(data?.total_estimate_amount ?? 0)?.toFixed(2)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <div className="d-flex ">
          <Link
            className=""
            to={
              order_statuses.includes(record?.estimate_status)
                ? "/order-details/" + record?.id
                : "/pro-forma-invoice-details/" + record?.id
            }
            target="_blank"
          >
            <Space size="middle">
              <Button
                size="small"
                className="fw-bold"
                type="primary"
                onClick={() => {}}
              >
                Details
              </Button>
            </Space>
          </Link>

          <DownloadOutlined
            className="px-2"
            style={{ fontSize: 20 }}
            onClick={() => {
              // setModal2Visible(true);
              // setPoDetails(data);
              // history("/app/project/estimate-details/" + data?.id);
              window.open(
                "https://api-dev2.materialdepot.in/apiV1/estimate-pdf/" +
                  record?.id +
                  "/?image=true",
                "_blank"
              );
            }}
          />
        </div>
      ),
    },
  ];

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [total_invoices, setTotalInvoices] = useState<number>(0);
  const [pi_list, setPIList] = useState<Array<any>>([]);
  const [pi_data, setPIData] = useState<any>(null);

  const [delivery_address_drawer_visible, setDeliveryAddressDrawerVisible] =
    useState(false);

  const [order_lost_drawer_visible, setOrderLostDrawerVisible] =
    useState(false);

  const getClientPIs = () => {
    if (!client_data || !client_data?.id) return;

    fetchProFormaInvoices({
      page: page,
      client: client_data?.id,
    }).then((res) => {
      setPIList(res?.results);
      setLoading(false);
      setTotalInvoices(res?.count);
    });
  };

  useEffect(() => {
    getClientPIs();
  }, [client_data]);

  const onDeliveryDetailsFinish = (values: any) => {
    setDeliveryAddressDrawerVisible(false);
    editPI(pi_data?.id, {
      ...values,
    }).then((res) => {
      setShowOrderPlacedDetailsDrawer(true);
    });
  };

  const onOrderLostReasonSubmit = (values: any) => {
    setOrderLostDrawerVisible(false);
    editPI(pi_data?.id, {
      ...values,
      estimate_status: "order_lost",
    }).then((res) => {
      getClientPIs();
    });
  };
  return (
    <div className=" p-3 py-2 row mb-2">
      <Table
        columns={columns}
        dataSource={pi_list}
        rowKey={"id"}
        loading={loading}
        pagination={false}
        scroll={{
          x: "100%",
        }}
      />
      <Pagination
        current={page}
        total={total_invoices ?? 1}
        showSizeChanger={false}
        pageSize={10}
        className="mt-3"
        onChange={(p) => {
          setLoading(true);
          fetchProFormaInvoices({
            page: p,
            client: client_data?.id,
          }).then((res) => {
            setPage(p);
            setPIList(res?.results);
            setLoading(false);
          });
        }}
      />
      <DeliveryAddressDrawer
        data={pi_data}
        visible={delivery_address_drawer_visible}
        onFinish={onDeliveryDetailsFinish}
        onClose={() => {
          setDeliveryAddressDrawerVisible(false);
        }}
      />
      <OrderLostReasonDrawer
        visible={order_lost_drawer_visible}
        onFinish={onOrderLostReasonSubmit}
        onClose={() => {
          setOrderLostDrawerVisible(false);
        }}
      />

      <Drawer
        title="Order Placed Details"
        placement="right"
        closable={false}
        onClose={() => {}}
        open={show_order_placed_details_drawer}
      >
        <OrderPlacedDetailsDrawer
          onFinish={onOrderPlacedDetailsFinish}
          onCancel={() => {
            setShowOrderPlacedDetailsDrawer(false);
          }}
        />
      </Drawer>
    </div>
  );
};

export default ClientPITable;
