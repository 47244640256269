import {
  Button,
  Checkbox,
  Drawer,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  addClientAddress,
  fetchClientAlternateContact,
  fetchClientDetails,
} from "../../../apis/client.api";
import { getAddressFromPincode } from "../../../apis/misc.api";
import { ProcurementMapPicker } from "../../misc/map-picker";
import AddClientAlternateContactForm from "../add_client_alternate_contact_form";
interface AddClientAddressFormProps {
  user_id: any;
  fetchAddresses: Function;
  onClientAddAddressDrawerClose: Function;
}
const { Option } = Select;
const { TextArea } = Input;

const AddClientAddressForm = ({
  fetchAddresses,
  onClientAddAddressDrawerClose,
  user_id,
}: AddClientAddressFormProps) => {
  const [form] = Form.useForm();
  const [alternate_contacts, setAlternateContacts] = useState<Array<any>>([]);
  const [disabledStateCountryCity, setDisabledStateCountryCity] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedMapAddress, setSelectedMapAddress] = useState<any>();
  const [showPlotNo, setShowPlotNo] = useState(true);
  const [showFloorNo, setShowFloorNo] = useState(false);
  const [useFlatNo, setUseFlatNo] = useState(false);

  const [
    client_alternate_contact_drawer_open,
    setClientAlternateContactDrawerOpen,
  ] = useState(false);
  const fetchAlternateContacts = (user_data: any) => {
    fetchClientAlternateContact(user_id).then((res) => {
      if (res) {
        setAlternateContacts([
          ...res,
          {
            id: user_data?.id,
            contact_person_name: user_data?.f_name,
            contact_person_contact: user_data?.contact,
          },
        ]);
      }
    });
  };

  const onFinish = async (updatedValues: any) => {
    try {
      setSubmitting(true);

      if (!selectedMapAddress) {
        message.error("Select a location from map");
        return;
      }

      addClientAddress(
        {
          ...updatedValues,
          gst_no: updatedValues?.gst_no,
          pan_no: updatedValues?.pan_no,
        },
        user_id
      ).then((res) => {
        fetchAddresses(user_id);
        onClientAddAddressDrawerClose();
        form.resetFields();
      });
    } catch (e) {
      message.error("Enter a valid google map link");
    } finally {
      setSubmitting(false);
    }
  };
  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        (reason) => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        loading={submitting}
      >
        Submit
      </Button>
    );
  };

  const fetchAllAlternateContacts = () => {
    fetchClientDetails(user_id).then((res) => {
      fetchAlternateContacts(res);
    });
  };

  useEffect(() => {
    fetchAllAlternateContacts();
  }, []);

  const setAddressFromPincode = (pincode: any) => {
    if (pincode?.length == 6) {
      getAddressFromPincode(pincode).then((res) => {
        if (res) {
          // Get currentFieldValues
          const currentFieldValues = form.getFieldsValue();
          setDisabledStateCountryCity(true);
          setTimeout(() => {
            // Reapply the field values
            form.setFieldsValue(currentFieldValues);

            form.setFieldValue("state", res?.state);
            form.setFieldValue("country", res?.country);
            form.setFieldValue("city", res?.city);
          }, 500);
        } else {
          message.error("Pincode doesn't exist");
        }
      });
    }
  };

  const onContactSelect = (value: string) => {
    const contact = alternate_contacts.find((contact) => {
      return contact?.id == value;
    });
    if (contact) {
      form.setFieldsValue({
        contact_person_name: contact?.contact_person_name,
        contact_person_contact: contact?.contact_person_contact,
      });
    } else {
      form.setFieldsValue({
        contact_person_name: "",
        contact_person_contact: "",
      });
    }
  };
  const onClientAlternateContactDrawerClose = () => {
    setClientAlternateContactDrawerOpen(false);
  };

  const gstChange = (e: any) => {
    let gst = e?.target?.value;
    if (gst?.length == 15) {
      form.setFieldValue("pan_no", gst?.slice(2, 12));
    }
  };

  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item label="" name="is_billing" valuePropName="checked">
        <Checkbox>Billing address</Checkbox>
      </Form.Item>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
        label="Address label"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="gender"
        label="Select contact details from alternate contact"
      >
        <div className="row">
          <div className="col-6">
            <Select
              placeholder="Select a option and change input text above"
              onChange={onContactSelect}
              allowClear
            >
              {alternate_contacts?.map((contact) => {
                return (
                  <Option value={contact?.id}>
                    {contact?.contact_person_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="col-6">
            <Button
              onClick={() => {
                setClientAlternateContactDrawerOpen(true);
              }}
            >
              Add Alternate Contact
            </Button>
          </div>
        </div>
      </Form.Item>
      <Form.Item
        name="contact_person_name"
        rules={[
          {
            required: true,
          },
          // {
          //   pattern: /^[a-zA-Z\s]*$/gi,
          //   message: "Enter a valid name",
          // },
        ]}
        label="Contact person name"
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="contact_person_contact"
        rules={[
          {
            required: true,
            pattern: /^[1-9]\d{9}$/gi,
            message: "Enter a valid Phone",
          },
        ]}
        label="Contact person phone"
      >
        <Input disabled />
      </Form.Item>
      <ProcurementMapPicker
        onPick={(selectedMapAddress) => {
          setSelectedMapAddress(selectedMapAddress);
          Object.entries(selectedMapAddress).forEach(([key, value]) => {
            form.setFieldValue(key, value);
          });
          if (!selectedMapAddress?.google_map_address) {
            form.setFieldValue(
              "google_map_address",
              `https://www.google.com/maps?q=${selectedMapAddress?.lat},${selectedMapAddress?.long}`
            );
          }
        }}
        openInModal={window.innerWidth > 768}
        pickedAddress={selectedMapAddress}
      />
      {/* <Form.Item
        rules={[
          {
            pattern: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
            message: "Enter a valid GST Number",
          },
        ]}
        name="gst_no"
        label="GST"
      >
        <Input onChange={gstChange} />
      </Form.Item>
      <Form.Item
        name="pan_no"
        label="PAN No."
        rules={[
          {
            pattern: /[A-Z]{5}\d{4}[A-Z]{1}/g,
            message: "Enter a valid PAN Number",
          },
        ]}
      >
        <Input />
      </Form.Item> */}
      <Space>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label="Address type"
          name={"address_type"}
          style={{ minWidth: 140, flexShrink: 0 }}
        >
          <Select
            placeholder="Select address type"
            onChange={(val) => {
              if (val === "villa") {
                setShowPlotNo(true);
                setShowFloorNo(false);
                setUseFlatNo(false);
              } else if (val === "apartment") {
                setShowPlotNo(true);
                setShowFloorNo(true);
                setUseFlatNo(true);
              } else {
                setShowFloorNo(true);
                setShowPlotNo(true);
                setUseFlatNo(false);
              }
            }}
          >
            <Option value="villa">Villa</Option>
            <Option value="apartment">Apartment</Option>
            <Option value="office">Office</Option>
            <Option value="restaurant">Restaurant</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="flat_no"
          rules={[
            {
              required: true,
            },
          ]}
          label={useFlatNo ? "Flat No." : "Plot No."}
        >
          <Input />
        </Form.Item>
        {showFloorNo && (
          <Form.Item
            name="floor_no"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^[1-9]\d*$/gi,
                message: "Enter a valid floor number",
              },
            ]}
            label="Floor No."
          >
            <Input />
          </Form.Item>
        )}
      </Space>
      <Form.Item hidden name="lat">
        <Input />
      </Form.Item>
      <Form.Item hidden name="long">
        <Input />
      </Form.Item>
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
          },
        ]}
        label="Address"
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        name="pincode"
        rules={[
          {
            required: true,
            pattern: /^[1-9]\d{5}$/gi,
            message: "Enter a valid pincode",
          },
        ]}
        label="Pincode"
      >
        <Input
          onChange={(e) => {
            setAddressFromPincode(e.target.value);
          }}
        />
      </Form.Item>
      <Space>
        <Form.Item
          name="city"
          rules={[
            {
              required: true,
            },
          ]}
          label="City"
        >
          <Input disabled={disabledStateCountryCity} />
        </Form.Item>
        <Form.Item
          name="state"
          rules={[
            {
              required: true,
            },
          ]}
          label="State"
        >
          <Input disabled={disabledStateCountryCity} />
        </Form.Item>
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
            },
          ]}
          label="Country"
        >
          <Input disabled={disabledStateCountryCity} />
        </Form.Item>
      </Space>
      <Form.Item
        rules={[
          {
            required: true,
          },
          {
            type: "url",
            message: "Enter a valid url",
          },
        ]}
        name="google_map_address"
        label="Google map location"
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        name="construction_type"
        label="Construction type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select placeholder="Select construction type">
          <Option value="new">New</Option>
          <Option value="renovation">Renovation</Option>
        </Select>
      </Form.Item> */}

      <Form.Item>
        <Space>
          <SubmitButton form={form} />
          {/* <Button htmlType="reset">Reset</Button> */}
        </Space>
      </Form.Item>
      <Drawer
        title="Add Alternate Contact"
        placement="right"
        onClose={onClientAlternateContactDrawerClose}
        open={client_alternate_contact_drawer_open}
        drawerStyle={{ height: "100%" }}
      >
        <AddClientAlternateContactForm
          user_id={user_id}
          onClientAlternateContactDrawerClose={() => {
            onClientAlternateContactDrawerClose();
            fetchAllAlternateContacts();
          }}
        />
      </Drawer>
    </Form>
  );
};

export default AddClientAddressForm;
