import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { fetchUserDetails } from "../apis/user.api";
import { usePermissions } from "../custom_hooks/permission/permission_hook";
import { getExplicitUserIdPermission } from "../custom_hooks/permission/role_container";

export interface ProtectedRouteProps {
  permission: any;
  children: any;
}

const ProtectedRoute = ({ permission, children }: ProtectedRouteProps) => {
  const { hasPermission } = usePermissions();
  const [currentUserDetails, setCurrentUserDetails] = useState<any>(null);

  useEffect(() => {
    fetchUserDetails().then(async (res) => {
      setCurrentUserDetails(res);
    });
  }, []);

  if (permission === "pi.create") {
    if (
      getExplicitUserIdPermission("pi.create").includes(currentUserDetails?.id)
    ) {
      return children;
    }
  }

  if (!hasPermission(permission)) {
    return <Navigate to={"/user-profile"} />;
  }
  return children;
};

export default ProtectedRoute;
