import { Button, DatePicker, Drawer, Input, Pagination, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  approveRefund,
  declineRefund,
  getOrderRefunds,
} from "../../apis/pro_forma_invoice.api";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";

export default function Refunds() {
  const { hasPermission } = usePermissions();
  const [data, setData] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [refund_details, setRefundDetails] = useState<any>(null);
  const [total_entries, setTotalEntries] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [refund_details_drawer_open, setRefundDetailsDrawerOpen] =
    useState(false);
  const [estimate__lead_id, setSearch] = useState("");
  const [created_at__gte, setCreatedAtGte] = useState("");
  const [created_at__lte, setCreatedAtLte] = useState("");
  const [estimate__order_placed_time__gte, setEstimateOrderPlacedTimeGTE] =
    useState("");
  const [estimate__order_placed_time__lte, setEstimateOrderPlacedTimeLTE] =
    useState("");

  const getRefunds = () => {
    setLoading(true);
    getOrderRefunds({
      page,
      estimate__lead_id,
      created_at__gte,
      created_at__lte,
      estimate__created_at__gte: estimate__order_placed_time__gte,
      estimate__created_at__lte: estimate__order_placed_time__lte,
    }).then((res: any) => {
      setData(res?.results);
      setTotalEntries(res?.count);
      setLoading(false);
    });
  };

  useEffect(() => {
    getRefunds();
  }, [
    page,
    created_at__gte,
    created_at__lte,
    estimate__lead_id,
    estimate__order_placed_time__gte,
    estimate__order_placed_time__lte,
  ]);

  const approveOrderRefund = async (id: any) => {
    setLoading(true);
    await approveRefund({ id });
    setLoading(false);
    getRefunds();
  };

  const declineOrderRefund = async (id: any) => {
    setLoading(true);
    await declineRefund({ id });
    setLoading(false);
    getRefunds();
  };

  const columns: ColumnsType<any> = [
    {
      key: "lead_id",
      title: "Lead ID",
      render: (data) => <div>{data?.estimate?.lead_id}</div>,
    },
    {
      title: "Refund created at",
      key: "created_at",
      render: (data) => <div>{new Date(data?.created_at).toDateString()}</div>,
    },
    {
      title: "Order placed at",
      key: "order_placed_time",
      render: (data) => (
        <div>{new Date(data?.estimate?.order_placed_time).toDateString()}</div>
      ),
    },
    {
      title: "Total Order Amount",
      key: "total_estimate_amount",
      render: (data) => <div>{data?.estimate?.total_estimate_amount}</div>,
    },
    {
      key: "total_refund",
      title: "Total Refund",
      render: (data) => <div>{data?.estimate?.total_refund_amount}</div>,
    },
    {
      key: "is_full_refund",
      title: "Refund Type",
      render: (data) => (
        <div className="text-capitalize">
          {data?.is_full_refund ? "Full Refund" : "Partial Refund"}
        </div>
      ),
    },
    {
      key: "Status",
      title: "Refund Status",
      render: (data) => <div className="text-capitalize">{data?.status}</div>,
    },

    {
      title: "Actions",
      key: "action",
      render: (data) => (
        <>
          <Button
            type="primary"
            className="me-2"
            onClick={() => {
              setRefundDetails(data);
              setRefundDetailsDrawerOpen(true);
            }}
          >
            Refund Details
          </Button>
        </>
      ),
    },
  ];

  const onRefundDetailsDrawerClose = () => {
    setRefundDetailsDrawerOpen(false);
  };

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div className="d-flex justify-content-between">
        <h3>Refunds</h3>

        <Space>
          <DatePicker
            placeholder="Refund Created At"
            onChange={(date, dateString) => {
              // On clear, set gte to empty string, and lte to empty string
              if (!date) {
                setCreatedAtGte("");
                setCreatedAtLte("");
                return;
              }
              // Set gte to that date, and lte a day after
              setCreatedAtGte(dateString);
              const next = dayjs(dateString).add(1, "day").format("YYYY-MM-DD");
              setCreatedAtLte(next);
            }}
            allowClear
          />

          <DatePicker
            placeholder="Estimate Created At"
            onChange={(date, dateString) => {
              // On clear, set gte to empty string, and lte to empty string
              if (!date) {
                setEstimateOrderPlacedTimeGTE("");
                setEstimateOrderPlacedTimeLTE("");
                return;
              }
              // Set gte to that date, and lte a day after
              setEstimateOrderPlacedTimeGTE(dateString);
              const next = dayjs(dateString).add(1, "day").format("YYYY-MM-DD");
              setEstimateOrderPlacedTimeLTE(next);
            }}
            allowClear
          />

          <Input.Search
            type="text"
            style={{ maxWidth: 180 }}
            placeholder="Search"
            onSearch={(value) => {
              setSearch(value);
            }}
          />
        </Space>
      </div>
      <Table
        dataSource={data}
        loading={loading}
        columns={columns}
        scroll={{ x: "100%" }}
        pagination={false}
      />
      <Pagination
        current={page}
        total={total_entries ?? 1}
        showSizeChanger={false}
        pageSize={10}
        className="mt-3"
        onChange={(p) => {
          setPage(p);
        }}
      />
      <Drawer
        title={"Refund Details : " + refund_details?.estimate?.lead_id}
        placement="right"
        closable={true}
        width={600}
        onClose={onRefundDetailsDrawerClose}
        open={refund_details_drawer_open}
        extra={
          <Space>
            {refund_details?.status == "initiated" ? (
              <div
                style={{
                  color: "#00A36C",
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                Initiated
              </div>
            ) : (
              <>
                {refund_details?.status != "pending" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <Button
                      type="primary"
                      disabled={!hasPermission("refund.approve")}
                      onClick={() => {
                        declineOrderRefund(refund_details?.id);
                        onRefundDetailsDrawerClose();
                      }}
                      danger
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Decline Refund
                    </Button>
                    <Button
                      type="primary"
                      disabled={!hasPermission("refund.approve")}
                      onClick={() => {
                        approveOrderRefund(refund_details?.id);
                        onRefundDetailsDrawerClose();
                      }}
                      style={{
                        background: "#00A36C",
                        fontWeight: 600,
                      }}
                    >
                      Approve Refund
                    </Button>
                  </>
                )}
              </>
            )}
          </Space>
        }
      >
        <div>
          {refund_details?.refund_item?.map((item: any) => {
            return (
              <>
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  href={
                    "https://materialdepot.in/" +
                    `/${item?.estimate_item?.variant_handle}/product`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <pre>{JSON.stringify(order_item, null, 2)}</pre> */}
                  <div className="py-3 row mb-2">
                    <div className="col-3 ">
                      <img
                        src={item?.estimate_item?.item_image}
                        className="w-100 rounded"
                        style={{ aspectRatio: 1, objectFit: "cover" }}
                      />
                    </div>
                    <div className="col-9" style={{ color: "black" }}>
                      <div>
                        <div>
                          <div
                            className="th-fontSize-18"
                            style={{ color: "black", fontWeight: 600 }}
                          >
                            {item?.estimate_item?.private_label_product_name}
                          </div>
                        </div>
                        <div>
                          <div className="th-fontSize-16 my-2 ">
                            <div>
                              <span>Quantity: {item?.refund_quantity}</span>
                            </div>
                          </div>
                          <div className="th-fontSize-16 my-2 ">
                            <div>
                              <span>Amount: {item?.refund_amount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
}
