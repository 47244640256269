import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Pagination,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { getFormattedDate } from "../../../helpers/date_helper";

import moment from "moment";
import { useEffect, useState } from "react";
import { fetchBranchMembers, fetchBranches } from "../../../apis/branch.api";
import { fetchClientDetails } from "../../../apis/client.api";
import {
  checkAndConfirmOrderPayment,
  createPIfromOrderID,
  editPI,
  fetchPIRevisions,
  fetchPIfromId,
  fetchProFormaInvoices,
} from "../../../apis/pro_forma_invoice.api";
import DeliveryAddressDrawer from "../../../components/pro_forma_invoice/delivery_address_drawer";
import OrderLostReasonDrawer from "../../../components/pro_forma_invoice/order_lost_drawer";
import { OrderPlacedDetailsDrawer } from "../../../components/pro_forma_invoice/order_placed_details_drawer";
import { PFIInvoiceForm } from "../../../components/pro_forma_invoice/pro_forma_invoice_form";
import ProformaInvoicePreview from "../../../components/pro_forma_invoice/pro_forma_invoice_modal";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { Client } from "../../../interfaces/client.interface";
const { Option } = Select;
const { RangePicker } = DatePicker;

interface DataType {
  id: number;
  created_by: string;
  assigned_to: null | string;
  organisation: number;
  vendor: string | null;
  terms_and_condition: number;
  total_estimate_amount: number;
  created_at: string;
  modified_at: string;
  estimate_payment_terms: Array<number>;
  documents: Array<any>;
  terms_and_conditions_description: string;
  is_deleted: false;
  deleted_by: string | null;
  gst: number;
  project: any;
  ship_address: string | null;
  ship_address_description: string | null;
  estimate_items: Array<number>;
  estimate_additional_charges: Array<number>;
  lead_id: string;
  payment_description: string | null;
  gst_description: string | null;
  bill_address: string | null;
  bill_address_description: string | null;
  is_draft: boolean;
  is_accepted: boolean;
  is_revised: boolean;
  status: string;
  estimate_no: string | null;
  estimate_status: string | null;
  lost_reason: string | string | null;
  image: boolean;
}

interface FilterType {
  estimate_status?: string | undefined;
  created_at__gte?: string | undefined;
  created_at__lte?: string | undefined;
  created_by?: string | undefined;
  assigned_to?: string | undefined;
}

const sortOptions = [
  { id: "1", value: "created_at", label: "Sort by Date Ascending" },
  { id: "2", value: "-created_at", label: "Sort by Date Descending" },
  { id: "3", value: "total_estimate_amount", label: "Sort by Total Ascending" },
  {
    id: "4",
    value: "-total_estimate_amount",
    label: "Sort by Total Descending",
  },
];

const order_statuses = [
  "order_placed",
  "order_confirmed",
  "partly_shipped",
  "shipped",
  "partly_delivered",
  "delivered",
  "refunded",
];
const ProFormaInvoicesClients = () => {
  const { hasPermission } = usePermissions();
  const [pushToZohoInProgress, setPushToZohoInProgress] = useState(false);
  const [totalEstimateAmountGTE, setTotalEstimateAmountGTE] =
    useState<number>(0);

  const columns: ColumnsType<DataType> = [
    {
      title: "Lead ID",
      key: "lead_id",
      render: (data) => (
        <>
          {" "}
          <div style={{ minWidth: 150 }}>{data?.lead_id}</div>
          {data?.is_outside_delivery && (
            <Tag color="orange" className="mt-1">
              Outstation Delivery
            </Tag>
          )}{" "}
          {data?.is_store_pickup && (
            <Tag color="green" className="mt-1">
              Store Pickup
            </Tag>
          )}
          {data?.refund_status && (
            <Tag color="cyan" className="mt-1">
              {data?.refund_status == "full" && <>Fully Refunded</>}
            </Tag>
          )}
        </>
      ),
    },
    // {
    //   title: "Pro Forma Invoice No.",
    //   key: "estimate_no",
    //   render: (data) => (
    //     <div style={{ minWidth: 160 }}>{data?.estimate_no}</div>
    //   ),
    // },
    {
      title: "Client",
      key: "client_data",
      render: (data) => (
        <div style={{ minWidth: 160 }}>{data?.client?.f_name}</div>
      ),
    },
    {
      title: "Creation date",
      key: "creation_date",
      render: (data) => (
        <div style={{ minWidth: 100 }}>
          {getFormattedDate(data?.created_at)}
        </div>
      ),
    },
    {
      title: "Created by",
      key: "created_by",
      render: (data) => <div style={{ minWidth: 100 }}>{data?.created_by}</div>,
    },
    {
      title: "Assigned to",
      key: "assigned_to",
      render: (data) => (
        <div style={{ minWidth: 100 }}>{data?.assigned_to?.f_name}</div>
      ),
    },
    {
      title: "Status",
      key: "estimate_status",
      render: (data) => (
        <Space>
          <Select
            style={{ width: 210 }}
            onChange={(e) => {
              console.log("e", e);
              if (
                (data?.ship_address !== null && e === "order_placed") ||
                (e !== "order_placed" && e !== "order_lost")
              ) {
                if (e === "order_placed") {
                  setPIData({
                    ...data,
                    bill_address: data?.bill_address?.id,
                    ship_address: data?.ship_address?.id,
                    estimate_status: e,
                  });
                  setShowOrderPlacedDetailsDrawer(true);
                  return;
                }
                editPI(data?.id, { estimate_status: e }).then((res) => {
                  getProFormaInvoices({
                    page,
                    estimate_status__in: status__in,
                  });
                });
              } else if (e === "order_lost") {
                setPIData({
                  ...data,
                  bill_address: data?.bill_address?.id,
                  ship_address: data?.ship_address?.id,
                });
                setOrderLostDrawerVisible(true);
              } else {
                setPIData({
                  ...data,
                  bill_address: data?.bill_address?.id,
                  ship_address: data?.ship_address?.id,
                });
                setDeliveryAddressDrawerVisible(true);
              }
            }}
            disabled={
              hasPermission("order.update")
                ? false
                : order_statuses.includes(data?.estimate_status)
            }
            placeholder="Select status"
            value={data?.estimate_status}
            options={statusOptions.map((item: any) => ({
              value: item?.value,
              label: item?.label,
              disabled: item?.disabled ?? false,
            }))}
          ></Select>
        </Space>
      ),
    },
    {
      title: "No. of POs raised",
      key: "po_count",
      render: (data) => (
        <div
          style={{
            width: 80,
          }}
        >
          {data?.po_count ?? ""}
        </div>
      ),
    },
    {
      title: "Total item quantity",
      key: "estimate_item_count",
      render: (data) => (
        <div
          style={{
            width: 80,
          }}
        >
          {data?.item_count?.estimate_item_count ?? ""}
        </div>
      ),
    },
    {
      title: "Po raised item quantity",
      key: "po_count",
      render: (data) => (
        <div
          style={{
            width: 150,
          }}
        >
          {data?.item_count?.po_item_count ?? ""}
        </div>
      ),
    },
    {
      title: "Total",
      key: "total_estimate_amount",
      width: 180,
      render: (data) => (
        <>{parseFloat(data?.total_estimate_amount ?? 0)?.toFixed(2)}</>
      ),
    },
    {
      title: "No. of invoices",
      key: "no_of_invoices",
      render: (data) => (
        <>
          {parseFloat(data?.estimate_invoice_data?.invoice_count ?? 0)?.toFixed(
            2
          )}
        </>
      ),
    },
    {
      title: "Total Invoice Amount",
      key: "total_invoice_amount",
      render: (data) => (
        <>
          {parseFloat(
            data?.estimate_invoice_data?.total_invoice_value ?? 0
          )?.toFixed(2)}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <div className="d-flex ">
          <Link
            className="me-2"
            to={
              order_statuses.includes(record?.estimate_status)
                ? "/order-details/" + record?.id
                : "/pro-forma-invoice-details/" + record?.id
            }
            target="_blank"
          >
            <Space size="middle">
              <Button size="small" type="primary" onClick={() => {}}>
                Details
              </Button>
            </Space>
          </Link>
          {(record?.estimate_status == "draft" ||
            record?.estimate_status == "quote_approval_pending") && (
            <>
              <Space size="middle">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setLoading(true);
                    checkAndConfirmOrderPayment(record?.id).then((res) => {
                      setLoading(false);
                      if (res) {
                        message.success("Successfully marked the order placed");
                        getPIs();
                      }
                    });
                  }}
                >
                  Check and confirm payment
                </Button>
              </Space>
            </>
          )}
          <Tag
            onClick={() => {
              setInvoiceDrawerVisible(true);
              setActivePoId(record?.id);
            }}
            color={"green"}
            style={{ cursor: "pointer", marginLeft: 8 }}
          >
            Add Invoice
          </Tag>

          {/* {["shipped", "partly_shipped", "delivered", "partly_delivered"].includes(record?.estimate_status) && (
            <Tag
              onClick={async () => {
                if (pushToZohoInProgress) return;
                setPushToZohoInProgress(true);
                await pushEstimateToZoho(record?.id);
                setPushToZohoInProgress(false);
              }}
              style={{ cursor: pushToZohoInProgress ? "not-allowed" : "pointer" }}
            >
              Push to Zoho
            </Tag>
          )} */}

          <DownloadOutlined
            className="px-2"
            style={{ fontSize: 20 }}
            onClick={() => {
              // setModal2Visible(true);
              // setPoDetails(data);
              // history("/app/project/estimate-details/" + data?.id);
              window.open(
                "https://api-dev2.materialdepot.in/apiV1/estimate-pdf/" +
                  record?.id +
                  "?image=true",
                "_blank"
              );
            }}
          />
          {/* 
          <CopyOutlined
            style={{ fontSize: 20 }}
            onClick={() => {
              message.loading({
                content: "Action in progress..",
                duration: 30,
                key: "duplicate",
              });
              createEstimateDuplicate(record?.id?.toString() ?? "").then(
                (res) => {
                  if (res) {
                    message.success({
                      type: "success",
                      content: "Duplicate created successfully",
                      duration: 2,
                      key: "duplicate",
                    });
                  } else {
                    message.warning({
                      type: "warning",
                      content: "Something went wrong please try again later.",
                      duration: 2,
                      key: "duplicate",
                    });
                  }
                  setPage(1);
                  getProFormaInvoices({ page: 1, status__in });
                }
              );
            }}
          /> */}

          {/* <EyeOutlined
            className="px-2"
            style={{ fontSize: 20 }}
            onClick={() => {
              setPIId(record?.id.toString());
              setBasicModal(!basicModal);
            }}
          /> */}
        </div>
      ),
    },
    {
      title: "Revisions",
      key: "revisions",
      render: (data) => {
        return (
          <Button
            size="small"
            onClick={() => {
              getPIRevisions(data?.lead_id);
            }}
            style={{ cursor: "pointer" }}
          >
            Revisions
          </Button>
        );
      },
    },
  ];

  const statusOptions = [
    { id: "1", value: "draft", label: "Draft", disabled: true },
    {
      id: "13",
      value: "incomplete",
      label: "Incomplete",
      disabled: !hasPermission("refund.approve"),
    },
    {
      id: "2",
      value: "availability_check",
      label: "Request Delivery Timeline",
    },
    {
      id: "3",
      value: "quote_approval_pending",
      label: "Quote Approval pending",
    },
    {
      id: "4",
      value: "order_placed",
      label: "Order Placed",
      disabled: hasPermission("refund.approve") ? false : true,
    },
    {
      id: "5",
      value: "order_lost",
      label: "Order lost",
    },
    {
      id: "12",
      value: "order_cancelled",
      label: "Order Cancelled",
    },
    {
      id: "6",
      value: "order_confirmed",
      label: "Order confirmed",
      disabled: true,
    },
    {
      id: "7",
      value: "partly_shipped",
      label: "Partly shipped",
      disabled: true,
    },
    {
      id: "8",
      value: "shipped",
      label: "Shipped",
      disabled: true,
    },
    {
      id: "9",
      value: "partly_delivered",
      label: "Partly delivered",
      disabled: true,
    },
    {
      id: "10",
      value: "delivered",
      label: "Delivered",
      disabled: true,
    },
    {
      id: "11",
      value: "refunded",
      label: "Refunded",
    },
  ];
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<any>>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [tab_type, setTabType] = useState("draft,quote_approval_pending");
  const [show_order_placed_details_drawer, setShowOrderPlacedDetailsDrawer] =
    useState(false);

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [status__in, setStatus__in] = useState("draft,quote_approval_pending");
  const [search, setSearch] = useState("");
  const [invoice_drawer_visible, setInvoiceDrawerVisible] = useState(false);
  const [activePoId, setActivePoId] = useState<string>("");

  const [total_invoices, setTotalInvoices] = useState<number>(0);
  const [estimate_revision_drawer_visible, setEstimateRevisionDrawerVisible] =
    useState(false);
  const [create_pi_order_id_drawer_visible, setCreatePIOrderIdDrawerVisible] =
    useState(false);
  const [order_id, setOrderId] = useState("");
  const [pi_data, setPIData] = useState<any>(null);
  const [revision_data, setRevisionData] = useState<Array<any>>([]);
  const [delivery_address_drawer_visible, setDeliveryAddressDrawerVisible] =
    useState(false);
  const [order_lost_drawer_visible, setOrderLostDrawerVisible] =
    useState(false);
  const [user_id, setUserId] = useState<any>(null);

  const [members, setMembers] = useState([]);
  const [client_data, setClientData] = useState<Client | null>(null);
  const [basicModal, setBasicModal] = useState(false);
  const [pIId, setPIId] = useState("");
  const [data1, setData1] = useState<any>({});
  const [branch_data, setBranchData] = useState<any>({});
  const [sortVal, setSortVal] = useState<string>();

  const [filter, setFilter] = useState<FilterType>();

  const handleReset = () => {
    setFilter(undefined);
    setStatus__in("draft,quote_approval_pending");
  };

  const getProFormaInvoices = (params: any) => {
    setLoading(true);

    setPage(params?.page ?? 1);
    fetchProFormaInvoices({
      page: params?.page ?? 1,
      ...params,
    }).then((res) => {
      let final_data = res?.results?.map((item: any) => {
        if (item.estimate_revision?.length > 0) {
          return { ...item, children: item.estimate_revision };
        } else {
          return item;
        }
      });

      setData(final_data);
      setLoading(false);
      setTotalInvoices(res?.count);
    });
  };

  const getPIRevisions = (lead_id: string) => {
    setEstimateRevisionDrawerVisible(true);
    fetchPIRevisions(lead_id).then((res) => {
      setRevisionData(res);
    });
  };

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };
  // useEffect(() => {
  //   let stage =
  //     "approval_pending,draft,availability_check,order_confirmed,under_dispatched,delivered";

  //   getProFormaInvoices({ page, estimate_status: stage });
  // }, []);

  useEffect(() => {
    fetchPIfromId(pIId).then((res) => {
      setData1({ ...res, po_items: res?.estimate_items });
    });
    fetchBranches().then((res) => {
      setBranchData(res);
    });
  }, [pIId]);

  useEffect(() => {
    if (data1?.client) {
      fetchBasicDetails(data1?.client);
    }
  }, [data1]);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setMembers(res);
    });
  }, []);

  const getPIs = () => {
    getProFormaInvoices({
      page: 1,
      ...filter,
      search,
      ordering: sortVal,
      estimate_status__in: status__in,
    });
  };

  useEffect(() => {
    console.log(filter);
    getPIs();
  }, [filter, sortVal, status__in]);

  const onDeliveryDetailsFinish = (values: any) => {
    setDeliveryAddressDrawerVisible(false);
    editPI(pi_data?.id, {
      ...values,
    }).then((res) => {
      setShowOrderPlacedDetailsDrawer(true);
    });
  };

  const onOrderLostReasonSubmit = (values: any) => {
    setOrderLostDrawerVisible(false);
    editPI(pi_data?.id, {
      ...values,
      estimate_status: "order_lost",
    }).then((res) => {
      getProFormaInvoices({ page, estimate_status__in: status__in });
    });
  };

  const onOrderPlacedDetailsFinish = (values: any) => {
    setShowOrderPlacedDetailsDrawer(false);
    editPI(pi_data?.id, {
      ...values,
      order_placed_time: moment(new Date()).add(5.5, "hours").toISOString(),
      estimate_status: "order_placed",
    }).then((res) => {
      // message.success("PI updated successfully");
      message.info(
        "Whatsapp message sent to client. PI will be marked as order placed once client confirms."
      );
      getProFormaInvoices({ page, estimate_status__in: status__in });
    });
  };

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div>
        <div className="d-flex justify-content-between">
          <h3>Pro Forma Invoices</h3>
          <div className="d-flex flex-row gap-2">
            {/* <div>
              <Select
                placeholder="Sort by"
                className="pt-0"
                style={{ minWidth: "200px" }}
                showArrow={true}
                value={sortVal}
                onChange={setSortVal}
                options={sortOptions.map((item: any) => ({
                  value: item?.value,
                  label: item?.label,
                }))}
              ></Select>
            </div> */}
            {dropdownVisible && <Button onClick={handleReset}>Reset</Button>}
            <Button className="pt-0" onClick={handleButtonClick}>
              <FilterOutlined style={{ fontSize: 16 }} />
            </Button>
            <Input.Search
              type="text"
              style={{ maxWidth: 180 }}
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onSearch={(value) => {
                setFilter(undefined);
                setSortVal(undefined);
                setStatus__in(
                  "draft,quote_approval_pending,order_placed,order_confirmed,order_lost,partly_shipped,shipped,partly_delivered,delivered,refunded,availability_check,order_cancelled"
                );
                setLoading(true);
                getPIs();

                // getProFormaInvoices({
                //   page: 1,
                //   search,
                //   estimate_status:
                //     "approval_pending,draft,availability_check,order_confirmed,under_dispatched,delivered",
                // });
              }}
            />
          </div>
        </div>

        {dropdownVisible && (
          <div className="row pb-3">
            <div className="d-flex flex-row justify-content-between">
              <div className="mr-2 col ">
                <RangePicker
                  style={{ width: "95%" }}
                  onChange={(event) => {
                    if (event) {
                      setFilter({
                        ...filter,
                        created_at__lte: event[1]
                          ?.add(1, "day")
                          ?.format("YYYY-MM-DD"),
                        created_at__gte: event[0]?.format("YYYY-MM-DD"),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        created_at__gte: undefined,
                        created_at__lte: undefined,
                      });
                    }
                  }}
                />
                <div className="th-font-size-14 px-1">Choose Date</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Select Created by"
                  style={{ width: "95%" }}
                  value={filter?.created_by}
                  onChange={(e) => {
                    setFilter({ ...filter, created_by: e });
                  }}
                  options={members.map((item: any) => ({
                    value: item?.user?.id,
                    label: item?.user?.f_name,
                  }))}
                ></Select>
                <div className="th-font-size-14 px-1">Created by</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Select Assigned To"
                  style={{ width: "95%" }}
                  value={filter?.assigned_to}
                  onChange={(e) => {
                    setFilter({ ...filter, assigned_to: e });
                  }}
                  options={members.map((item: any) => ({
                    value: item?.user?.id,
                    label: item?.user?.f_name,
                  }))}
                ></Select>
                <div className="th-font-size-14 px-1">Assigned To</div>
              </div>
              <div className="mr-2 col" style={{ maxWidth: "25%" }}>
                <Select
                  placeholder="Estimate Status"
                  style={{ width: "95%" }}
                  value={status__in}
                  onChange={(e) => {
                    setStatus__in(e);
                  }}
                  options={statusOptions.map((item: any) => ({
                    value: item?.value,
                    label: item?.label,
                    disabled: false,
                  }))}
                ></Select>
                <div className="th-font-size-14 px-1">Estimate Status</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Amount"
                  allowClear
                  style={{ width: "95%" }}
                  value={totalEstimateAmountGTE}
                  onChange={(e) => {
                    setTotalEstimateAmountGTE(e);
                    getProFormaInvoices({
                      ...filter,
                      page: 1,
                      estimate_status__in: status__in,
                      total_estimate_amount__gte: e ?? 0,
                      ordering: sortVal,
                    });
                  }}
                  options={[
                    { value: 0, label: "All" },
                    { value: 1000, label: "1K+" },
                    { value: 5000, label: "5K+" },
                    { value: 10000, label: "10K+" },
                    { value: 50000, label: "50K+" },
                    { value: 100000, label: "1L+" },
                    { value: 500000, label: "5L+" },
                    { value: 1000000, label: "10L+" },
                  ]}
                />
                <div className="th-font-size-14 px-1">Estimate Value</div>
              </div>
            </div>
          </div>
        )}
        {/* <div>{dropdownVisible && <FilterButton />}</div> */}
        <div className="d-flex justify-content-between">
          <div>
            <Radio.Group
              value={status__in}
              onChange={(e) => {
                setStatus__in(e.target.value);
              }}
              style={{ marginBottom: 16 }}
            >
              <Radio.Button value="draft,quote_approval_pending">
                Draft/Quote Approval Pending
              </Radio.Button>
              <Radio.Button value="availability_check">
                Request Delivery Timeline
              </Radio.Button>
              <Radio.Button value="order_placed">
                Order Processing Pending
              </Radio.Button>
              <Radio.Button value="order_confirmed,partly_shipped,shipped,partly_delivered,delivered,refunded">
                Processed Orders
              </Radio.Button>
              <Radio.Button value="order_cancelled">
                Cancelled Orders
              </Radio.Button>
              <Radio.Button value="order_lost">Orders Lost</Radio.Button>
            </Radio.Group>
          </div>
          {hasPermission("pi.create") && (
            <div>
              <Button
                size="middle"
                className="fw-bold"
                type="primary"
                onClick={() => {
                  setCreatePIOrderIdDrawerVisible(true);
                }}
              >
                Create PI from Order ID
              </Button>
            </div>
          )}
        </div>
        <div className="my-2">
          <Table
            columns={columns}
            style={{ minHeight: 600 }}
            dataSource={data}
            rowKey={"id"}
            loading={loading}
            pagination={false}
            scroll={{
              x: "100%",
            }}
          />
          <Pagination
            current={page}
            total={total_invoices ?? 1}
            showSizeChanger={false}
            pageSize={10}
            className="mt-3"
            onChange={(p) => {
              getProFormaInvoices({
                page: p,
                ...filter,
                search,
                ordering: sortVal,
                estimate_status__in: status__in,
                total_estimate_amount__gte: totalEstimateAmountGTE,
              });
            }}
          />
        </div>
      </div>

      <Modal
        open={basicModal}
        width={1000}
        footer={null}
        onOk={() => setBasicModal(false)}
        onCancel={() => setBasicModal(false)}
      >
        <ProformaInvoicePreview
          id={pIId}
          data={data1}
          client_data={client_data}
        />
      </Modal>

      <Drawer
        title={"Pro Forma Invoice Details"}
        width={800}
        onClose={() => {
          setEstimateRevisionDrawerVisible(false);
        }}
        open={estimate_revision_drawer_visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        {!revision_data && <div className="mt-2">Loading...</div>}
        {revision_data?.map((data, index) => {
          return (
            <div key={(index + Math.random())?.toString()}>
              <div>
                <div className="row">
                  <div className="col" style={{ margin: "auto" }}>
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#928d8d",
                          fontWeight: "500",
                        }}
                      >
                        Lead Id{" "}
                      </div>
                      <div className="fw-bold" style={{ fontSize: "22px" }}>
                        {data?.lead_id}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#928d8d",
                        fontWeight: "500",
                      }}
                    >
                      Created by{" "}
                      <span className="fw-bold" style={{ color: "#000" }}>
                        {" "}
                        {data?.revised_by__name}{" "}
                      </span>
                    </div>{" "}
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#928d8d",
                        fontWeight: "500",
                      }}
                    >
                      Created at:
                      <span className="fw-bold" style={{ color: "#000" }}>
                        {getFormattedDate(data?.created_at)}
                      </span>
                    </div>{" "}
                    <br />
                    <Link
                      className="text-decoration-none"
                      to={
                        order_statuses.includes(data?.estimate_status)
                          ? "/order-details/" + data?.id
                          : "/pro-forma-invoice-details/" + data?.id
                      }
                      target="_blank"
                    >
                      <Button
                        onClick={(e) => {
                          // goToProject(data.project);
                          // message.loading({
                          //   content: "Action in progress..",
                          //   duration: 1,
                          //   key: "duplicate",
                          // });
                          // setTimeout(() => {
                          //   message.success({
                          //     content: "Opened invoice",
                          //     duration: 1,
                          //     key: "duplicate",
                          //   });
                          //   history(
                          //     "/app/project/estimate-details/" + data?.id
                          //   );
                          // }, 500);
                        }}
                        type="primary"
                        className="btn d-flex align-items-center justify-content-center border go-to-po-btn"
                      >
                        <div
                          style={{
                            marginLeft: "4px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          }}
                        >
                          See Estimate
                        </div>
                      </Button>
                    </Link>
                  </div>
                  <div className="col">
                    <div style={{ height: 42 }}></div>
                    <div className="px-2">
                      <div className="" style={{ color: "#928d8d" }}>
                        Pro Forma Invoice No:{" "}
                      </div>
                      <div className="text-right">
                        {data?.estimate_no}{" "}
                        {data?.revision && `(R${data?.revision})`}
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="" style={{ color: "#928d8d" }}>
                        Status:{" "}
                      </div>
                      <div className="text-right">
                        <Tag
                          style={{ textTransform: "capitalize" }}
                          color={data?.status == "draft" ? "gold" : "green"}
                        >
                          {data.status ?? "Raised"}
                        </Tag>
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="" style={{ color: "#928d8d" }}>
                        Total:{" "}
                      </div>
                      <div className="text-right">
                        {Number(data?.total_estimate_amount)?.toFixed(2)}
                      </div>
                    </div>

                    <div
                      style={{
                        background: "#f2f2f2",
                        padding: "2px 10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="" style={{ fontSize: "12px" }}>
                        Inclusive of all taxes
                      </div>
                      <div
                        className="text-right fw-bold"
                        style={{ fontSize: "25px" }}
                      >
                        {"₹"}
                        {Number(data?.total_estimate_amount)?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Drawer>
      <DeliveryAddressDrawer
        data={pi_data}
        visible={delivery_address_drawer_visible}
        onFinish={onDeliveryDetailsFinish}
        onClose={() => {
          setDeliveryAddressDrawerVisible(false);
        }}
      />
      <OrderLostReasonDrawer
        visible={order_lost_drawer_visible}
        onFinish={onOrderLostReasonSubmit}
        onClose={() => {
          setOrderLostDrawerVisible(false);
        }}
      />
      <Drawer
        title="Order Placed Details"
        placement="right"
        closable={false}
        onClose={() => {}}
        open={show_order_placed_details_drawer}
      >
        <OrderPlacedDetailsDrawer
          onFinish={onOrderPlacedDetailsFinish}
          onCancel={() => {
            setShowOrderPlacedDetailsDrawer(false);
          }}
        />
      </Drawer>
      <Drawer
        title={"Create PI from Order ID"}
        width={400}
        onClose={() => {
          setCreatePIOrderIdDrawerVisible(false);
        }}
        open={create_pi_order_id_drawer_visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        <div>
          {" "}
          <Input
            className="mb-3"
            value={order_id}
            placeholder="Order ID..."
            onChange={(e) => {
              setOrderId(e.target.value);
            }}
          />
          <Button
            size="middle"
            className="fw-bold"
            type="primary"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await createPIfromOrderID({ order_id });
              setLoading(false);
              setCreatePIOrderIdDrawerVisible(false);
              getProFormaInvoices({ page, estimate_status__in: status__in });
            }}
          >
            Create PI
          </Button>
        </div>
      </Drawer>
      <div>
        <Drawer
          title="Add Invoice"
          placement="right"
          closable={false}
          onClose={() => {
            setInvoiceDrawerVisible(false);
          }}
          open={invoice_drawer_visible}
        >
          <PFIInvoiceForm
            pfi_id={activePoId}
            onClose={() => setInvoiceDrawerVisible(false)}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default ProFormaInvoicesClients;
