import { Button, Divider, Modal, Select, message } from "antd";
import { Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { fetchBranches } from "../../../apis/branch.api";
import {
  fetchClientAddresses,
  fetchClientDetails,
} from "../../../apis/client.api";
import {
  createPurchaseOrder,
  getPurchaseOrderDetails,
} from "../../../apis/po.api";
import { fetchVendors, getVendor } from "../../../apis/vendor.api";
import POEditAdditionalCharges from "../../../components/po/po_edit/po_additional_charges";
import PoItemTable from "../../../components/po/po_edit/po_item_table";
import POEditOrderDetails from "../../../components/po/po_edit/po_order_details";
import POPaymentTerms from "../../../components/po/po_edit/po_payment_terms";
import POSelectBillingDetails from "../../../components/po/po_edit/po_select_billing_details";
import { POEditShipToAddress } from "../../../components/po/po_edit/po_ship_to_address";
import TaxAmount from "../../../components/po/po_edit/po_tax_amount";
import POTermsAndConditionsSelect from "../../../components/po/po_edit/po_terms_and_conditions";
import { POEditVendorDetails } from "../../../components/po/po_edit/po_vendor_selector";
import {
  checkIfAllValuesArePresentPO,
  getMissingFieldsPO,
} from "../../../helpers/pfi_checks";
import { useCallbackPrompt } from "../../../helpers/useCallback";
import { Client } from "../../../interfaces/client.interface";
import styles from "./index.module.scss";

const POEdit = () => {
  const { po_id } = useParams();
  const navigate = useNavigate();

  const [vendors, setVendors] = useState<Array<any>>([]);

  const [client_data, setClientData] = useState<Client | null>(null);
  const [branch_data, setBranchData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [missing_fields, setMissingFields] = useState<Array<any>>([]);
  const [client_addresses, setClientAddresses] = useState<Array<any>>([]);
  const [saved, setSaved] = useState(false);
  const [startedEditing, setStartedEditing] = useState(true);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(startedEditing);

  useEffect(() => {
    if (saved) {
      setStartedEditing(false);
    }
  }, [saved]);

  useEffect(() => {
    if (saved && !startedEditing) {
      setLoading(false);
      navigate("/po-list");
    }
  }, [navigate, saved, startedEditing]);

  const defaultTaxes = [
    {
      id: 1,
      label: "None",
      value: "none",
      tax_value: 0,
    },
    {
      id: 6,
      label: "5% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 5,
    },
    {
      id: 7,
      label: "12% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 12,
    },
    {
      id: 8,
      label: "18% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 18,
    },
    {
      id: 9,
      label: "28% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 28,
    },
    {
      id: 2,
      label: "5% IGST",
      value: "igst",
      tax_value: 5,
    },
    {
      id: 3,
      label: "12% IGST",
      value: "igst",
      tax_value: 12,
    },
    {
      id: 4,
      label: "18% IGST",
      value: "igst",
      tax_value: 18,
    },
    {
      id: 5,
      label: "28% IGST",
      value: "igst",
      tax_value: 28,
    },
  ];
  const [data, setData] = useState<any>({});
  const fetchBasicDetails = (client_id: any) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };
  const fetchAddresses = (client_id: any) => {
    fetchClientAddresses(client_id).then((res) => {
      setClientAddresses(res);
    });
  };
  useEffect(() => {
    getVendors();
    fetchBranches().then((res) => {
      setBranchData(res);
    });
    getPurchaseOrderDetails(po_id ?? "").then((res) => {
      let dataRes = res;
      fetchBasicDetails(
        dataRes?.estimate?.client?.id ?? dataRes?.estimate?.client
      );
      fetchAddresses(
        dataRes?.estimate?.client?.id ?? dataRes?.estimate?.client
      );

      dataRes.po_items = dataRes.po_items?.map((item: any) => {
        return {
          ...item,
          ...item?.estimate_item,
          id: item.id,
          esitimate_item: item?.estimate_item?.id,
          total_price: item?.total_price,
          tax_value: item?.tax_value,
          tax_type: item?.tax_type,
        };
      });

      setData({
        ...dataRes,
        vendor: dataRes?.vendor?.id,
        gst: dataRes?.gst?.id,
        estimate: dataRes?.estimate?.id,
        address: dataRes?.address?.id,
        address_description: dataRes?.address_description,
        terms_and_condition: dataRes?.terms_and_condition?.id,
      });
    });
  }, []);

  useEffect(() => {
    setDataSource("branch", branch_data?.id);
  }, [branch_data]);
  useEffect(() => {}, [data]);

  const setDataSource = (key: string, value: any) => {
    if (data[key] !== value) {
      let new_data = { ...data };
      new_data[key] = value;

      setData(new_data);
    }
  };

  const getTotalAmount = () => {
    let amount = 0;
    data?.po_items?.map((item: any) => {
      amount +=
        parseFloat(item?.quantity ?? 0) *
        (parseFloat(item?.unit_po_price ?? 0) *
          ((parseFloat(item?.tax_value ?? 0) + 100) / 100));
    });
    amount += data?.po_additional_charges?.reduce((acc: number, obj: any) => {
      const amount = parseFloat(obj?.amount ?? 0);
      const taxP = parseFloat(obj?.tax_value ?? 0);
      const taxAmt = (1 + taxP / 100) * amount;

      return acc + (taxAmt ?? 0);
    }, 0);

    let new_data = { ...data };
    new_data.total_po_raised_amount = amount;

    return amount;
  };

  const getVendors = () => {
    fetchVendors({}).then((res) => {
      setVendors(res);
    });
  };

  const createPO = async ({ isDraft = false }) => {
    setLoading(true);
    let requiredDetailsFilled = true;

    if (data?.po_items?.length < 1) {
      message.error("Enter at least one item!");
      setLoading(false);

      return;
    }
    if (!data?.dispatch_time) {
      message.error("Enter dispatch time");
      setLoading(false);
      return;
    }

    // Get vendor warehouses
    const vendorWarehouses = await getVendor(data?.vendor);
    if (
      vendorWarehouses &&
      Array.isArray(vendorWarehouses?.warehouses) &&
      vendorWarehouses?.warehouses?.length > 0
    ) {
      if (!data?.vendor_warehouse) {
        message.error("Select vendor warehouse");
        setLoading(false);
        return;
      }
    }

    if (typeof data?.vendor_warehouse === "object") {
      data.vendor_warehouse = data?.vendor_warehouse?.id;
    }

    const full_percentage = data?.po_payment_terms?.reduce(
      (acc: number, obj: any) => {
        return acc + obj.percentage;
      },
      0
    );
    if (full_percentage !== 100) {
      message.error("Total Percentage for terms should be 100!");
      setLoading(false);
      return;
    }
    for (var i = 0; i < data?.po_items?.length; i++) {
      var item_data = data?.po_items[i];
      const formData = {
        estimate_item: item_data?.estimate_item?.id ?? item_data?.estimate_item,
        quantity: item_data.quantity,
        unit_po_price: item_data.unit_po_price,
        tax_value: item_data.tax_value,
        tax_type: item_data.tax_type,
        product_handle: item_data.product_handle,
        variant_handle: item_data.variant_handle,
        total_price: (
          Number(item_data?.quantity) *
          ((Number(item_data?.unit_po_price) *
            (Number(item_data?.tax_value) + 100)) /
            100)
        ).toFixed(2),
      };

      data.po_items[i] = formData;

      const currentFilled = checkIfAllValuesArePresentPO(formData, isDraft);
      if (!currentFilled) {
        const currentMissingFields = getMissingFieldsPO(formData);
        setMissingFields([
          ...missing_fields,
          {
            index: i,
            missingFields: currentMissingFields,
          },
        ]);
      }
      requiredDetailsFilled = currentFilled;
      requiredDetailsFilled = checkIfAllValuesArePresentPO(formData, isDraft);
    }
    if (!requiredDetailsFilled) {
      message.error("Please fill all the required details of po items.");
      setLoading(false);

      return;
    }

    let po_data = { ...data, po_id: po_id };
    delete po_data.created_by;

    let address_data = client_addresses.find(
      (addr: any) => addr.id === data?.address
    );
    createPurchaseOrder({
      ...po_data,
      address: data?.address,
      address_description:
        address_data?.address +
        ", " +
        address_data?.city +
        ", " +
        address_data?.pincode,
      total_po_raised_amount: (
        Math.ceil(Number(getTotalAmount()) * 100) / 100
      ).toFixed(2),
    }).then((res) => {
      setSaved(true);
    });
  };
  const setTaxForAllItems = (tax_id: any) => {
    const tax_selected = defaultTaxes.find((tax) => tax.id == tax_id);
    const allItems = [...data?.po_items];
    allItems?.map((item) => {
      item.tax_type = tax_selected?.value;
      item.tax_value = tax_selected?.tax_value;
    });
    setDataSource("po_items", allItems);
  };
  return (
    <section className={`lighter-grey-color-bg`}>
      <Modal
        title="Confirm leaving the page?"
        open={showPrompt}
        onOk={async () => {
          cancelNavigation();
          // setLoading(false);
          // confirmNavigation();
        }}
        onCancel={() => {
          confirmNavigation();
        }}
        okText="Cancel"
        cancelText="Leave without saving"
      >
        <p style={{ margin: "0 1rem" }}>Unsaved changes might be lost.</p>
      </Modal>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4 align-items-center">
            <div className="primary-color-text th-font-size-20 th-font-weight-700">
              PO Edit
            </div>
            <div className=" th-font-weight-600">
              Client :
              <span className=" th-font-weight-400">
                {" "}
                {client_data?.f_name}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Button
              loading={loading}
              onClick={() => {
                createPO({ isDraft: false });
              }}
              type="primary"
              className="th-font-weight-700 d-flex align-items-center"
            >
              <div>Save PO</div>
            </Button>
          </div>
        </div>
      </Header>
      <div className={styles.table_content}>
        <div className="m-4 p-4 px-5 white-color-bg">
          <img
            alt="logo"
            src="/main_logo.svg"
            style={{ height: "80px" }}
            className="mt-2"
          />
          <div className="row gap-2 my-4">
            <div className="col">
              <POSelectBillingDetails setData={setDataSource} data={data} />
            </div>
            <div className="col">
              <POEditOrderDetails
                client_data={client_data}
                setData={setDataSource}
                data={data}
              />
            </div>
          </div>
          <div className="row gap-2 my-4">
            <div className="col ">
              <>
                <POEditShipToAddress
                  client_addresses={client_addresses}
                  setData={setData}
                  data={data}
                />
              </>
            </div>
            <div className="col">
              <>
                <POEditVendorDetails
                  client_addresses={client_addresses}
                  setData={setData}
                  data={data}
                  vendors={vendors}
                />
              </>
            </div>
          </div>
          <Divider />
          <div className="mt-3 d-flex justify-content-end">
            <div className="d-flex gap-3">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div style={{ width: 180 }}>
                  <div style={{ textAlign: "right" }}>
                    Set tax for all items
                  </div>
                  <Select
                    size="middle"
                    style={{ textAlign: "right", width: "100%" }}
                    onChange={(value) => setTaxForAllItems(value)}
                    key="all_tax"
                  >
                    {defaultTaxes.map((tax) => (
                      <Select.Option
                        value={tax.id}
                        style={{ textAlign: "right" }}
                        key={tax.id}
                      >
                        {tax.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div>
            <PoItemTable data={data} setData={setDataSource} />
          </div>{" "}
          <Divider />
          <div className="row">
            <div className="col">
              <div>
                <POPaymentTerms setData={setDataSource} data={data} />
              </div>
              <Divider />
              <div>
                <POTermsAndConditionsSelect
                  branch_data={branch_data}
                  setData={setDataSource}
                  data={data}
                />
              </div>
              {/* <Divider /> */}

              {/* <div>
                <div className="">
                  <div>
                    <h6 className="th-font-weight-600">Documents</h6>{" "}
                    {"(Total files size should be less than 25MB)"}
                  </div>
                  <PFICreateDocumentUpload
                    documents={data?.documents}
                    loading={false}
                    setData={setDataSource}
                    client_id={data?.client ?? ""}
                  />
                  {data?.documents?.map((document: any, index: any) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <div
                          style={{
                            border: "1px #D1D0CE solid",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            // openInNewTab(document?.file);
                          }}
                        >
                          {document?.file_name ??
                            document?.file?.replace(
                              "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/media/porject_files/",
                              ""
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
            <div className="col">
              <div>
                <TaxAmount items={data?.po_items ?? []} />
              </div>
              <Divider />
              <div>
                <POEditAdditionalCharges setData={setDataSource} data={data} />
              </div>
              <Divider />
              <div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="">
                    ₹{" "}
                    {(Math.ceil(Number(getTotalAmount()) * 100) / 100).toFixed(
                      2
                    )}
                    (Rounded off)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default POEdit;
