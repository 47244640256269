import { DatePicker, message, Tooltip } from "antd";
import { useState } from "react";
import { UserProperty } from ".";
import { updateOrCreateUserProperty } from "../../../apis/user.api";

export interface ClientPropertiesDetailsProps {
  properties: UserProperty[];
  clientId?: string;
}

export function ClientPropertiesDetails({
  properties,
  clientId,
}: ClientPropertiesDetailsProps) {
  const [followUpDate, setFollowUpDate] = useState<string | undefined>(
    properties?.find((property) => property.property.name === "Follow Up Date")
      ?.value
  );

  const onDateChange = async (date: any) => {
    if (!clientId) {
      message.error("Unable to update Follow Up Date");
      return;
    }
    // Update Follow Up Date user property
    const propertyId = 7;
    const payload = {
      property_id: propertyId,
      value: date.format("YYYY-MM-DD"),
    };

    await updateOrCreateUserProperty({
      user_id: clientId,
      properties: [payload],
    });

    setFollowUpDate(date.format("YYYY-MM-DD"));

    message.success("Follow Up Date updated successfully!");
  };

  if (!properties || properties.length === 0) {
    return null;
  }

  return (
    <div className="">
      <div>
        <div className="align-middle th-font-size-18 th-font-weight-600 mb-3 primary-color-text">
          User Properties
        </div>
      </div>
      {properties?.map((property) => {
        return (
          <div className="row mb-1" key={property?.id}>
            <div className="col-4 th-font-weight-500">
              {property?.property?.name}
            </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div
                  style={{
                    // Ellipsis
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {property?.value?.includes("https://") ? (
                    <a href={property?.value} target="_blank" rel="noreferrer">
                      {property?.value}
                    </a>
                  ) : (
                    <Tooltip title={property?.value}>{property?.value}</Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-4 th-font-weight-500">Update Follow Up Date</div>
        <div className="col-7">
          <div className="d-flex">
            <div className="pe-1">:</div>
            <div>
              <DatePicker
                onChange={(date, dateString) => {
                  onDateChange(date);
                }}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
