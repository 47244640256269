import { SearchOutlined } from "@ant-design/icons";
import { Button, message, Pagination } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchBranchMembers } from "../../../apis/branch.api";
import { assignClientBM, fetchClients } from "../../../apis/client.api";
import { fetchUserDetails, getUserProperties } from "../../../apis/user.api";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";

export function MobileClientsList() {
  const [clients, setClients] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [currentOrgUser, setCurrentOrgUser] = useState<any>(null);
  const [currentUserDetails, setCurrentUserDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [transientSearchValue, setTransientSearchValue] = useState<string>("");
  const [error, setError] = useState(false);
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchClients({ params: { page, search } }).then((res) => {
      if (!res) {
        setLoading(false);
        setError(true);
      }
      const { count, results } = res;
      setClients(results);
      setCount(count);
      setLoading(false);
    });
  }, [page, search]);

  useEffect(() => {
    fetchUserDetails().then(async (res) => {
      setCurrentUserDetails(res);
      const branchUsers = await fetchBranchMembers({ search: "" });
      const orgUser = branchUsers.find(
        (branchUser: any) => branchUser?.user?.id === res?.id
      );

      setCurrentOrgUser(orgUser);
    });
  }, []);

  const assignBMToClient = async (client_id: string) => {
    await assignClientBM(
      client_id,
      currentOrgUser?.id,
      currentOrgUser?.user_permission
    );
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-center">
        <h3 className="fw-bold mb-3">Clients</h3>
        {/* <div></div> */}
      </div>
      {/* <Input.Search
        onSearch={(value) => {
          setSearch(value);
        }}
        placeholder="Search"
        className="mb-3"
        size="large"
      /> */}
      <div className="d-flex gap-2 mb-4 align-items-center">
        <input
          className="form-control form-control-lg rounded-pill bg-light"
          style={{
            fontSize: 14,
          }}
          placeholder="Search"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearch(e.currentTarget.value);
            }
          }}
          onChange={(e) => setTransientSearchValue(e.target.value)}
          type="tel"
        />
        <Button
          icon={<SearchOutlined />}
          shape="circle"
          size="large"
          type="primary"
          onClick={() => setSearch(transientSearchValue)}
        />
        <Link to="/client/add">
          <Button type="primary" className="rounded-pill" size="large">
            Add Client
          </Button>
        </Link>
      </div>
      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}
      {error && (
        <div className="text-center alert alert-error">
          Error getting data from server
        </div>
      )}
      {/* <div className="text-muted mb-3">
        Showing {page === 1 ? 1 : (page - 1) * 10 + 1} -{" "}
        {page * 10 > count ? count : page * 10} of {count}
      </div> */}
      {/* If clients are empty and search is a valid 10 digit number,
      show prompt to add client. */}
      {clients.length === 0 &&
        search &&
        /^[0-9]{10}$/.test(search) &&
        !loading && (
          <div className="d-flex justify-content-between my-3 p-3 rounded shadow-sm">
            <div>
              Client not found, do you want to{" "}
              <Link to={`/client/add/?contact=${search}`}>
                add this client?
              </Link>
            </div>
          </div>
        )}
      {clients.length === 0 &&
        !(search && /^[0-9]{10}$/.test(search)) &&
        !loading && (
          <div className="text-center alert alert-info">No Clients Found</div>
        )}
      {clients.map((client) => (
        <div className="p-3 rounded shadow-sm mb-3 d-block">
          <div className="d-flex justify-content-between mb-1">
            {client?.f_name ? (
              <div>{client?.f_name}</div>
            ) : (
              <div className="text-muted">No Name</div>
            )}
            <div className="th-fontSize-12 text-muted">
              {moment(client?.created_at).fromNow()}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: ".5rem 0",
            }}
          >
            <div>
              <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
                Contact
              </div>
              {client?.contact}
            </div>
            <div>
              <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
                Name
              </div>
              {client?.f_name ?? "Not Set"}
            </div>
            <div>
              <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
                Cart ID
              </div>
              {client?.user_info?.cart_number}
            </div>
            {client?.user_manager?.manager_details && (
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  BM
                </div>
                {client?.user_manager?.manager_details?.f_name}
              </div>
            )}
            {client?.cart_user && (
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Cart Value
                </div>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(
                  client?.cart_user?.reduce((acc: number, item: any) => {
                    const itemPrice =
                      Number(item?.quantity) *
                      Number(item?.variant?.md_price_gst);
                    return acc + itemPrice;
                  }, 0)
                )}
              </div>
            )}
          </div>
          <div className="d-flex gap-3 mt-3">
            <a
              href={`https://materialdepot.in/auto-login-v2?use_procurement_user_login=${client?.id}&procurement_user=${currentUserDetails?.id}&use_procurement_user_contact=${client?.contact}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
              onClick={async (ev) => {
                ev.preventDefault();

                message.loading({
                  content: "Assigning BM...",
                  key: "cart-bm-assign",
                });
                // assignBMToClient(client?.id);
                // Get client properties to check if source and requirements are filled
                const clientProperties = await getUserProperties(client?.id);

                const requiredProperties = hasPermission(
                  "pi.create_without_properties"
                )
                  ? ["Requirements", "Source", "Location"]
                  : [
                      "Requirements",
                      "Source",
                      "Location",
                      "Type of Property",
                      // "Property",
                      "Project Type",
                      "Family",
                      "Age",
                    ];

                const missingProperties = requiredProperties.filter(
                  (property) =>
                    !clientProperties?.find(
                      (userProperty: any) =>
                        userProperty?.property?.name === property
                    )
                );

                if (missingProperties.length > 0) {
                  message.error({
                    content: `Please enter the following properties for the client: ${missingProperties.join(
                      ", "
                    )}`,
                    duration: 8,
                    key: "cart-bm-assign",
                  });

                  navigate(`/client/${client?.id}/edit`);
                  return;
                }

                await assignBMToClient(client?.id);

                // Also copy the link to clipboard
                const link = `https://materialdepot.in/auto-login-v2?use_procurement_user_login=${client?.id}&procurement_user=${currentUserDetails?.id}&use_procurement_user_contact=${client?.contact}`;
                navigator.clipboard.writeText(link);
                message.info("Link copied to clipboard");

                window.open(link, "_blank");
              }}
            >
              <Button type="primary">Create Cart</Button>{" "}
            </a>
            <Link to={`/client/${client?.id}`}>
              <Button
                onClick={() => {
                  // setViewDetails(client?.id);
                }}
              >
                View Details
              </Button>
            </Link>
          </div>
        </div>
      ))}
      <div className="d-flex justify-content-center">
        <Pagination
          total={count}
          // showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={page}
          onChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
}
