import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Radio,
  Select,
  Space,
  Tabs,
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { fetchBranchMembers } from "../../../apis/branch.api";
import { assignClientBM, editClient } from "../../../apis/client.api";
import {
  getUserProperties,
  getUserPropertyList,
  updateOrCreateUserProperty,
} from "../../../apis/user.api";
import {
  ClientProperties,
  UserInfoProperty,
  UserProperty,
} from "../client_properties";

const { Option } = Select;
interface EditClientFormProps {
  client: any;
  onClientEditDrawerClose: Function;
  fetchBasicDetails: Function;
  fetchExtraDetails?: () => void;
}
const EditClientForm = ({
  client,
  onClientEditDrawerClose,
  fetchBasicDetails,
  fetchExtraDetails,
}: EditClientFormProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState<"details" | "properties">("details");
  const [properties, setProperties] = useState<UserInfoProperty[]>([]);
  const [userProperties, setUserProperties] = useState<UserProperty[]>([]);
  const [branchMembers, setBranchMembers] = useState<any[]>([]);
  const [requirementsOptionCounterMap, setRequirementsOptionCounterMap] =
    useState<any>({});
  const [optionalRequirementOthers, setOptionalRequirementOthers] =
    useState<string>("");

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setBranchMembers(res);
    });
  }, []);

  useEffect(() => {
    getUserPropertyList().then((res) => {
      setProperties(res);
    });
    getUserProperties(client?.id).then((res) => {
      setUserProperties(res);
    });
  }, [client?.id]);

  const computedUserProperties = useMemo(() => {
    return userProperties.reduce((acc, property) => {
      acc[property.property.id] = property.value;
      if (property.property.name.includes("Date") && property.value) {
        acc[property.property.id] = moment(property.value);
      }
      if (property.property.name.includes("Attachment")) {
        acc[property.property.id] = property.value;
      }
      if (property.property.name.includes("Requirements")) {
        try {
          const parsed = JSON.parse(property.value);
          delete parsed["Others"];
          setRequirementsOptionCounterMap(parsed);
          setOptionalRequirementOthers(
            JSON.parse(property.value)?.Others ?? ""
          );

          return acc;
        } catch (e) {
          acc[property.property.id] = property.value.split(",");
        }
      }

      return acc;
    }, {} as Record<string, string | string[] | moment.Moment | null>);
  }, [userProperties]);

  useEffect(() => {
    if (!computedUserProperties) return;

    // Find source property
    const source = properties?.find((p) => p?.name === "Source");
    const requirements = properties?.find((p) => p?.name === "Requirements");
    const location = properties?.find((p) => p?.name === "Location");
    const age = properties?.find((p) => p?.name === "Age");
    const family = properties?.find((p) => p?.name === "Family");
    const projectType = properties?.find((p) => p?.name === "Project Type");
    const typeOfProperty = properties?.find(
      (p) => p?.name === "Type of Property"
    );
    const des_arch = properties?.find(
      (p) => p?.name === "Has Designer / Architect?"
    );

    if (
      !source ||
      !requirements ||
      !location ||
      !age ||
      !family ||
      !projectType ||
      !typeOfProperty ||
      !des_arch
    )
      return;

    const sourceProperty = computedUserProperties[source?.id];
    const requirementsProperty = computedUserProperties[requirements?.id];
    const locationProperty = computedUserProperties[location?.id];
    const ageProperty = computedUserProperties[age?.id];
    const familyProperty = computedUserProperties[family?.id];
    const projectTypeProperty = computedUserProperties[projectType?.id];
    const typeOfPropertyProperty = computedUserProperties[typeOfProperty?.id];
    const des_archProperty = computedUserProperties[des_arch?.id];

    // Set source value
    form.setFieldsValue({
      source: sourceProperty?.toString(),
      requirements: requirementsProperty,
      location: locationProperty,
      age: ageProperty?.toString(),
      family: familyProperty?.toString(),
      project_type: projectTypeProperty?.toString(),
      type_of_property: typeOfPropertyProperty?.toString(),
      des_arch: des_archProperty?.toString(),
    });
  }, [computedUserProperties, properties]);

  const onFinish = (updatedValues: any) => {
    setLoading(true);
    console.log("updatedValues", updatedValues);
    const source = updatedValues?.source;
    const location = updatedValues?.location;
    const age = updatedValues?.age;
    const type_of_property = updatedValues?.type_of_property;
    const project_type = updatedValues?.project_type;
    const family = updatedValues?.family;
    const des_arch = updatedValues?.des_arch;

    // If all the requirement object properties are 0, show error message and return early.
    if (Object.values(requirementsOptionCounterMap).every((val) => val === 0)) {
      message.error("Please increment at least one requirement");
      setLoading(false);
      return;
    }

    // updatedValues.roles = 10;

    const payloadForPropertyUpdation = {
      user_id: client?.id,
      properties: [] as Array<any>,
    };

    if (source) {
      const sourceProperty = properties?.find((p) => p?.name === "Source");
      payloadForPropertyUpdation.properties.push({
        property_id: sourceProperty?.id,
        value: source,
      });

      delete updatedValues?.source;
    }

    const requirementsProperty = properties?.find(
      (p) => p?.name === "Requirements"
    );
    const req = {
      ...requirementsOptionCounterMap,
      Others: optionalRequirementOthers,
    };

    payloadForPropertyUpdation.properties.push({
      property_id: requirementsProperty?.id,
      value: JSON.stringify(req),
    });

    delete updatedValues?.requirements;

    if (age) {
      const ageProperty = properties?.find((p) => p?.name === "Age");
      payloadForPropertyUpdation.properties.push({
        property_id: ageProperty?.id,
        value: age,
      });

      delete updatedValues?.age;
    }

    if (type_of_property) {
      const typeOfPropertyProperty = properties?.find(
        (p) => p?.name === "Type of Property"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: typeOfPropertyProperty?.id,
        value: type_of_property,
      });

      delete updatedValues?.type_of_property;
    }

    if (project_type) {
      const projectTypeProperty = properties?.find(
        (p) => p?.name === "Project Type"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: projectTypeProperty?.id,
        value: project_type,
      });

      delete updatedValues?.project_type;
    }

    if (family) {
      const familyTypeProperty = properties?.find((p) => p?.name === "Family");
      payloadForPropertyUpdation.properties.push({
        property_id: familyTypeProperty?.id,
        value: family,
      });

      delete updatedValues?.family;
    }

    if (location) {
      const locationProperty = properties?.find((p) => p?.name === "Location");
      payloadForPropertyUpdation.properties.push({
        property_id: locationProperty?.id,
        value: location,
      });

      delete updatedValues?.location;
    }

    if (des_arch) {
      const desArchProperty = properties?.find(
        (p) => p?.name === "Has Designer / Architect?"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: desArchProperty?.id,
        value: des_arch,
      });

      delete updatedValues?.des_arch;
    }

    if (payloadForPropertyUpdation.properties.length > 0) {
      updateOrCreateUserProperty(payloadForPropertyUpdation).then((res) => {
        console.log("Source updated", res);
      });
    }

    if (updatedValues?.bm) {
      const bmObj = branchMembers?.find((bm) => bm.id === updatedValues?.bm);
      assignClientBM(client?.id, bmObj?.id, bmObj?.user_permission);

      delete updatedValues?.bm;
    }

    editClient(
      {
        ...client,
        ...updatedValues,
      },
      client?.id
    ).then((res) => {
      setLoading(false);
      onClientEditDrawerClose();
      fetchBasicDetails();
    });
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        loading={loading}
      >
        Submit
      </Button>
    );
  };

  useEffect(() => {
    Object.keys(client).forEach((key: any) => {
      form.setFieldValue(key, client[key]);
    });
    form.setFieldsValue({
      bm: client?.user_manager?.manager,
    });
  }, [client]);

  const getView = () => {
    if (view === "details") {
      return (
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <div className="pb-3 th-font-weight-500">
            <div>Phone</div>
            <div>{client?.contact}</div>
          </div>

          {/* <Form.List name="alternate_contact">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              label={index === 0 ? "Alternate phone" : ""}
              required={false}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    pattern: /^[1-9]\d{9}$/gi,
                    message: "Enter a valid phone number",
                  },
                ]}
                noStyle
              >
                <Input placeholder="Phone" style={{ width: "80%" }} />
              </Form.Item>
              {fields.length > 0 ? (
                <MinusCircleOutlined
                  className="dynamic-delete-button mx-2"
                  onClick={() => remove(field.name)}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: "60%" }}
              icon={<PlusOutlined />}
            >
              Add alternate phone
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List> */}
          <div className="d-flex gap-2">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Enter a valid salutation",
                },
              ]}
              name="salutation"
              label="Salutation"
              style={{ width: 90 }}
            >
              <Select placeholder="Select salutation">
                <Option value="Mr">Mr</Option>
                <Option value="Mrs">Ms</Option>
                <Option value="Mx">Mx</Option>
              </Select>
            </Form.Item>
            <Form.Item
              required
              name="f_name"
              label="Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="l_name"
              label="Last Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "Enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            name="project_type"
            label="Project Type"
            rules={[
              {
                required: true,
                message: "Enter a valid project type",
              },
            ]}
          >
            <Select placeholder="Select project type">
              {properties
                ?.find((p) => p?.name === "Project Type")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="project_type"
            label="Project Type"
            rules={[
              {
                required: true,
                message: "Enter a valid project type",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Project Type")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>

          {/* <Form.Item
                      name="type_of_property"
                      label="Property Type"
                      rules={[
                        {
                          required: true,
                          message: "Enter a valid property type",
                        },
                      ]}
                    >
                      <Select placeholder="Select property type">
                        {properties
                          ?.find((p) => p?.name === "Type of Property")
                          ?.options?.map((option) => {
                            return <Option value={option}>{option}</Option>;
                          })}
                      </Select>
                    </Form.Item> */}
          <Form.Item
            name="type_of_property"
            label="Property Type"
            rules={[
              {
                required: true,
                message: "Enter a valid property type",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Type of Property")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: "Enter a valid location",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            name="source"
            label="Source"
            rules={[
              {
                required: true,
                message: "Enter a valid source",
              },
            ]}
          >
            <Select>
              {properties
                ?.find((p) => p?.name === "Source")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="source"
            label="Source"
            rules={[
              {
                required: true,
                message: "Enter a valid source",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Source")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          <div className="mb-2">
            <span className="text-danger">*</span> Requirements
          </div>
          <div
            id="requirements-grid"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: ".5rem",
            }}
          >
            {properties
              ?.find((p) => p?.name === "Requirements")
              ?.options?.map((option) => {
                return (
                  <div className="d-flex gap-1" key={option}>
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setRequirementsOptionCounterMap((prev: any) => {
                          const copyPrev = { ...prev };
                          copyPrev[option] = (copyPrev[option] ?? 0) + 1;
                          return copyPrev;
                        });
                      }}
                    />
                    <span>{requirementsOptionCounterMap[option] ?? 0}</span>
                    <Button
                      size="small"
                      icon={<MinusOutlined />}
                      onClick={() => {
                        setRequirementsOptionCounterMap((prev: any) => {
                          const copyPrev = { ...prev };
                          copyPrev[option] = (copyPrev[option] ?? 0) - 1;
                          if (copyPrev[option] < 0) {
                            copyPrev[option] = 0;
                          }
                          return copyPrev;
                        });
                      }}
                    />
                    <span className="flex-grow-1">{option}</span>
                  </div>
                );
              })}
            {/* Others */}
            <div className="d-flex gap-1 mb-3">
              <Input
                placeholder="Others"
                value={optionalRequirementOthers}
                onChange={(ev) => setOptionalRequirementOthers(ev.target.value)}
                size="small"
              />
            </div>
          </div>
          {/* <Form.Item
                      name="age"
                      label="Age"
                      rules={[
                        {
                          required: true,
                          message: "Enter a valid age",
                        },
                      ]}
                    >
                      <Select placeholder="Select age">
                        {properties
                          ?.find((p) => p?.name === "Age")
                          ?.options?.map((option) => {
                            return <Option value={option}>{option}</Option>;
                          })}
                      </Select>
                    </Form.Item> */}

          <Form.Item
            name="age"
            label="Age"
            rules={[
              {
                required: true,
                message: "Enter a valid age",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Age")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>

          {/* <Form.Item
                                name="family"
                                label="Family Type"
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter a valid family type",
                                  },
                                ]}
                              >
                                <Select placeholder="Select family type">
                                  {properties
                                    ?.find((p) => p?.name === "Family")
                                    ?.options?.map((option) => {
                                      return <Option value={option}>{option}</Option>;
                                    })}
                                </Select>
                              </Form.Item> */}
          <Form.Item
            name="family"
            label="Family Type"
            rules={[
              {
                required: true,
                message: "Enter a valid family type",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Family")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            label="Medium"
            name={"medium"}
            rules={[
              {
                required: true,
                message: "Enter a valid user medium",
              },
            ]}
          >
            <Select placeholder="Select user medium">
              <Option value="Fbig">Fbig</Option>
              <Option value="SEO">SEO</Option>
              <Option value="SEM">SEM</Option>
              <Option value="SMM">SMM</Option>
              <Option value="Referral">Referral</Option>
              <Option value="Offline">Offline</Option>
              <Option value="Newsletter">Email Marketing/Newsletter</Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            name="des_arch"
            label="Has Designer / Architect?"
            rules={[
              {
                required: true,
                message: "Enter a valid value",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Has Designer / Architect?")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          <Form.Item name="bm" label="BM">
            <Select
              placeholder="Select BM"
              showSearch
              filterOption={(input, option) =>
                ((option?.children as unknown as string) ?? "")
                  .toLowerCase()
                  ?.indexOf(input.toLowerCase()) >= 0
              }
            >
              {branchMembers
                ?.filter((bm) => bm?.user_permission === 3)
                ?.map((bm) => {
                  return <Option value={bm.id}>{bm.user.f_name}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <SubmitButton form={form} />
            </Space>
          </Form.Item>
        </Form>
      );
    }

    if (view === "properties") {
      return (
        <ClientProperties
          userId={client?.id}
          onComplete={() => {
            onClientEditDrawerClose();
            fetchBasicDetails();
            if (fetchExtraDetails) {
              fetchExtraDetails();
            }
          }}
          isStudio={client?.roles === 8}
          client={client}
        />
      );
    }
  };

  return (
    <>
      <Tabs
        items={[
          {
            key: "details",
            label: "Basic Details",
          },
          {
            key: "properties",
            label: "+ Details",
          },
        ]}
        activeKey={view}
        onChange={(view) => setView(view as "details" | "properties")}
        tabBarExtraContent={
          <Button onClick={() => onClientEditDrawerClose()}>Close</Button>
        }
      />
      {getView()}
    </>
  );
};

export default EditClientForm;
