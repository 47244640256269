import { Button, DatePicker, Form, Input, message, Select } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { editClient } from "../../../apis/client.api";
import {
  getUserProperties,
  getUserPropertyList,
  updateOrCreateUserProperty,
} from "../../../apis/user.api";
import { AttachmentInput } from "./attachment-input";

export interface UserInfoProperty {
  id: number;
  name: string;
  options: string[] | null;
  created_at: string;
  updated_at: string;
  required: boolean;
}

export interface UserProperty {
  id: number;
  property: UserInfoProperty;
  user: {
    f_name?: string;
    id: string;
    contact: number;
    email?: string;
  };
  value: string;
}

export interface ClientPropertiesProps {
  userId: string;
  onComplete?: () => void;
  isStudio?: boolean;
  client: any;
}

export function ClientProperties({
  userId,
  onComplete,
  isStudio: initIsStudio,
  client,
}: ClientPropertiesProps) {
  const [properties, setProperties] = useState<UserInfoProperty[]>([]);
  const [userProperties, setUserProperties] = useState<UserProperty[]>([]);
  const [propertyOptions, setPropertyOptions] = useState<string[]>([]);
  const [isStudio, setIsStudio] = useState(initIsStudio);
  const [form] = Form.useForm();

  useEffect(() => {
    getUserProperties(userId).then((res) => {
      setUserProperties(res);
    });
  }, [userId]);

  const computedUserProperties = useMemo(() => {
    return userProperties.reduce((acc, property) => {
      acc[property.property.id] = property.value;
      if (property.property.name.includes("Date") && property.value) {
        acc[property.property.id] = moment(property.value);
      }
      if (property.property.name.includes("Attachment")) {
        acc[property.property.id] = property.value;
      }
      if (property.property.name.includes("Requirements")) {
        acc[property.property.id] = property.value.split(",");
      }

      return acc;
    }, {} as Record<string, string | string[] | moment.Moment | null>);
  }, [userProperties]);

  useEffect(() => {
    form.setFieldsValue(computedUserProperties);
  }, [computedUserProperties]);

  const [attachmentMap, setAttachmentMap] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    getUserPropertyList().then((res) => {
      setProperties(res);
    });
  }, []);

  const renderInput = (property: UserInfoProperty) => {
    if (property?.name?.includes("Attachment")) {
      return (
        <AttachmentInput
          property={property}
          onChange={(url) => {
            setAttachmentMap({
              ...attachmentMap,
              [property.id]: url,
            });
          }}
        />
      );
    }

    if (property?.options) {
      let options = property.options;
      if (property?.name === "Property") {
        options = propertyOptions;
      }

      return (
        <Select
          mode={property?.name === "Requirements" ? "multiple" : undefined}
          optionFilterProp="value"
          showSearch
          onChange={(value: string) => {
            if (property?.name === "Type of Property") {
              if (value === "Commercial") {
                setPropertyOptions(["Restaurant", "Office", "Retail", "Other"]);
              } else if (value === "Residential") {
                setPropertyOptions(["Apartment", "Independent House"]);
              } else {
                setPropertyOptions([]);
              }
            }
          }}
        >
          {options.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );
    }

    if (property?.name?.includes("Date")) {
      return <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />;
    }

    return <Input />;
  };

  const onFinish = async (values: any) => {
    const gstNumber = values.gst_number;
    if (gstNumber === "29AARCM7928H1ZO") {
      message.error({
        content: "Cannot use this GST number as it's MD's GST number",
        key: "save",
      });
      return;
    }

    const clientParams = {
      roles: values.roles,
      gst_number: values.gst_number,
      company_name: values.company_name,
      whatsapp_group_link: values.whatsapp_group_link,
    };

    delete values.roles;
    delete values.gst_number;
    delete values.company_name;
    delete values.whatsapp_group_link;

    message.loading({ content: "Saving...", key: "save" });

    await editClient(
      {
        ...client,
        ...clientParams,
      },
      userId
    );

    // Convert date objects to string whenever the property name has Date in it
    const formattedValues = Object.keys(values).reduce((acc, key) => {
      if (
        properties
          .find((property) => String(property.id) === key)
          ?.name.includes("Date") &&
        values[key]
      ) {
        acc[key] = values[key]?.format("YYYY-MM-DD");
      } else {
        acc[key] = values[key];
      }

      if (Array.isArray(values[key])) {
        acc[key] = values[key].join(",");
      }

      return acc;
    }, {} as any);

    const payload = {
      user_id: userId,
      properties: Object.keys(formattedValues)
        .filter((key) => {
          // Discard values which are null or empty
          return formattedValues[key];
        })
        .map((key) => ({
          property_id: key,
          value: formattedValues[key],
        })),
    };

    const response = await updateOrCreateUserProperty(payload);
    if (response) {
      message.success({ content: "Saved successfully", key: "save" });
      onComplete?.();
    } else {
      message.error({ content: "Failed to save", key: "save" });
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
      initialValues={client}
    >
      <Form.Item
        label="User Type"
        name="roles"
        rules={[
          {
            required: true,
            message: "Enter a valid user type",
          },
        ]}
      >
        <Select
          placeholder="Select user type"
          onChange={(val) => {
            if (val === 8) {
              setIsStudio(true);
            } else {
              setIsStudio(false);
            }
          }}
        >
          <Select.Option value={10}>Public</Select.Option>
          <Select.Option value={8}>Studio</Select.Option>
        </Select>
      </Form.Item>
      {form.getFieldValue("roles") === 8 && (
        <>
          <Form.Item
            label="GST Number"
            name="gst_number"
            rules={[
              {
                required: false,
                pattern:
                  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
                message: "Enter a valid GST Number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Whatsapp group link"
            name="whatsapp_group_link"
            rules={[
              {
                type: "url",
                message: "Enter a valid URL",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            required={isStudio}
            label="Company name"
            name="company_name"
          >
            <Input />
          </Form.Item>
        </>
      )}
      {properties
        ?.filter((property) => {
          if (
            [
              "Location",
              "Requirements",
              "Source",
              "Property",
              "Type of Property",
              "Age",
              "Project Type",
              "Family Type",
            ].includes(property.name)
          ) {
            return false;
          }

          if (
            isStudio &&
            [
              "Type of Property",
              "Property",
              "Project Type",
              "Family",
              "Age",
            ].includes(property.name)
          ) {
            return false;
          }

          return true;
        })
        ?.map((property) => (
          <Form.Item
            key={property.id}
            label={property.name}
            name={property.id}
            rules={
              property?.required
                ? [
                    {
                      required: true,
                    },
                  ]
                : []
            }
            valuePropName={property?.name?.includes("Date") ? "date" : "value"}
          >
            {renderInput(property)}
          </Form.Item>
        ))}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
