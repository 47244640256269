import { Button, Drawer, Select, Space, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";
import { editPI } from "../../../../apis/pro_forma_invoice.api";
import { usePermissions } from "../../../../custom_hooks/permission/permission_hook";
import OrderLostReasonDrawer from "../../order_lost_drawer";
import { OrderPlacedDetailsDrawer } from "../../order_placed_details_drawer";

export interface BankDetails {
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
}
export interface PFIOrderDetailsProps {
  created_by: string;
  estimate_data: any;
  client: string;
  lead_id: string;
  quotation_no: string;
  stage: string;
  getPIData: Function;
  status: string;
  bank_details: BankDetails;
  setDeliveryAddressDrawerVisible: Function;
  assigned_to?: string;
}
const order_statuses = [
  "order_placed",
  "order_confirmed",
  "partly_shipped",
  "shipped",
  "partly_delivered",
  "delivered",
  "refunded",
];

const PFIOrderDetails = ({
  created_by,
  estimate_data,
  client,
  lead_id,
  getPIData,
  quotation_no,
  stage,
  status,
  bank_details,
  setDeliveryAddressDrawerVisible,
  assigned_to,
}: PFIOrderDetailsProps) => {
  const { hasPermission } = usePermissions();
  const [show_order_placed_details_drawer, setShowOrderPlacedDetailsDrawer] =
    useState(false);
  const [updateAsAdmin, setUpdateAsAdmin] = useState(false);

  const [order_lost_drawer_visible, setOrderLostDrawerVisible] =
    useState(false);
  const statusOptions = [
    { id: "1", value: "draft", label: "Draft", disabled: true },
    {
      id: "13",
      value: "incomplete",
      label: "Incomplete",
      disabled: !hasPermission("refund.approve"),
    },
    {
      id: "2",
      value: "availability_check",
      label: "Request Delivery Timeline",
    },
    {
      id: "3",
      value: "quote_approval_pending",
      label: "Quote Approval pending",
    },
    {
      id: "4",
      value: "order_placed",
      label: "Order Placed",
      disabled: hasPermission("refund.approve") ? false : true,
    },
    {
      id: "5",
      value: "order_lost",
      label: "Order lost",
    },
    {
      id: "12",
      value: "order_cancelled",
      label: "Order Cancelled",
    },
    {
      id: "6",
      value: "order_confirmed",
      label: "Order confirmed",
      disabled: true,
    },
    {
      id: "7",
      value: "partly_shipped",
      label: "Partly shipped",
      disabled: true,
    },
    {
      id: "8",
      value: "shipped",
      label: "Shipped",
      disabled: true,
    },
    {
      id: "9",
      value: "partly_delivered",
      label: "Partly delivered",
      disabled: true,
    },
    {
      id: "10",
      value: "delivered",
      label: "Delivered",
      disabled: true,
    },
    {
      id: "11",
      value: "refunded",
      label: "Refunded",
    },
  ];
  const onOrderLostReasonSubmit = (values: any) => {
    setOrderLostDrawerVisible(false);
    editPI(estimate_data?.id, {
      ...values,
      estimate_status: "order_lost",
    }).then((res) => {
      getPIData();
    });
  };

  const onOrderPlacedDetailsFinish = (values: any) => {
    setShowOrderPlacedDetailsDrawer(false);
    editPI(
      estimate_data?.id,
      {
        ...values,
        order_placed_time: moment(new Date()).add(5.5, "hours").toISOString(),
        estimate_status: "order_placed",
      },
      updateAsAdmin
    ).then((res) => {
      if (updateAsAdmin) {
        message.success("PI updated successfully");
      } else {
        message.info(
          "Whatsapp message sent to client. PI will be marked as order placed once client confirms."
        );
      }
      getPIData();
      setUpdateAsAdmin(false);
    });
  };

  return (
    <div className="p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Order Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Created By</div>
          <div className="col-8 pb-1">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{created_by}</div>
            </div>
          </div>
        </div>
        {assigned_to && (
          <div className="row py-2">
            <div className="col-4 th-font-weight-500">Assigned To</div>
            <div className="col-8 pb-1">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div>{assigned_to}</div>
              </div>
            </div>
          </div>
        )}
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Created At</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>
                {dayjs(estimate_data?.created_at).format("DD-MM-YYYY HH:mm")}
              </div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Client </div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{client}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Lead ID</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{lead_id}</div>
            </div>
          </div>
        </div>
        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Quotation No.</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{quotation_no}</div>
            </div>
          </div>
        </div> */}
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Quotation Status</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              {/* <div>{getPIStatusLabel(status)}</div> */}

              <Space>
                <Select
                  style={{ width: 210 }}
                  onChange={async (e) => {
                    if (
                      (estimate_data?.ship_address != null &&
                        e == "order_placed") ||
                      (e != "order_placed" && e != "order_lost")
                    ) {
                      if (e === "order_placed") {
                        setShowOrderPlacedDetailsDrawer(true);
                        return;
                      }

                      const payload = {
                        estimate_status: e,
                      } as Record<string, any>;
                      if (e === "order_placed") {
                        payload.order_placed_time = new Date().toISOString();
                      }
                      await editPI(estimate_data?.id, payload).then(
                        (res) => {}
                      );
                    } else if (e == "order_lost") {
                      setOrderLostDrawerVisible(true);
                    } else {
                      setDeliveryAddressDrawerVisible(true);
                    }

                    getPIData();
                  }}
                  disabled={
                    hasPermission("order.update")
                      ? false
                      : order_statuses.includes(status)
                  }
                  placeholder="Select status"
                  value={status}
                  options={statusOptions.map((item: any) => ({
                    value: item?.value,
                    label: item?.label,
                    disabled: item?.disabled ?? false,
                  }))}
                ></Select>
                {hasPermission("pi.mark_as_order_placed") && (
                  <Button
                    size="small"
                    type="primary"
                    onClick={async () => {
                      setUpdateAsAdmin(true);
                      setShowOrderPlacedDetailsDrawer(true);
                      // await editPI(
                      //   estimate_data?.id,
                      //   {
                      //     estimate_status: "order_placed",
                      //     order_placed_time: new Date().toISOString(),
                      //   },
                      //   true
                      // ).then((res) => {
                      //   getPIData();

                      //   message.success("PI updated successfully");
                      // });
                    }}
                  >
                    Mark as Placed (admin)
                  </Button>
                )}
              </Space>
            </div>
          </div>
        </div>

        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Payment Confirmation</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>
                https://materialdepot.in/external-payment/external-success?id=
                {estimate_data?.id}&lead_id={lead_id}
                <br />
                <Button
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://materialdepot.in/external-payment/external-success?id=${estimate_data?.id}&lead_id=${lead_id}`
                    );
                    message.success("Link copied to clipboard");
                  }}
                >
                  Copy Link
                </Button>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Bank Details</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="">
                <div>Account Number ({bank_details?.bank_account_number})</div>
                <div>IFSC Code ({bank_details?.bank_ifsc})</div>
                <div>UPI ({bank_details?.branch_upi})</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <OrderLostReasonDrawer
        visible={order_lost_drawer_visible}
        onFinish={onOrderLostReasonSubmit}
        onClose={() => {
          setOrderLostDrawerVisible(false);
        }}
      />

      <Drawer
        title="Order Placed Details"
        placement="right"
        closable={false}
        onClose={() => {}}
        open={show_order_placed_details_drawer}
      >
        <OrderPlacedDetailsDrawer
          onFinish={onOrderPlacedDetailsFinish}
          onCancel={() => {
            setShowOrderPlacedDetailsDrawer(false);
          }}
        />
      </Drawer>
    </div>
  );
};

export default PFIOrderDetails;
